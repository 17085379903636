import { ContentStore } from "@/entities/feed-block/model/content-store"
import { UserStore } from "@/entities/user"
import { IUser } from "@/entities/user/model/types"
import useNotifier from "@/shared/lib/use-notifier"
import { LangStore } from "@/shared/model/store"
import { ContractsStore } from "@/shared/model/store/contracts-store"
import FindUser from "@/shared/ui/user-find/find-user"

interface Props {
    closeModal: () => void
}

const TransferToken = ({closeModal}: Props) => {
    const { activePost } = ContentStore()
    const {contracts} = ContractsStore()
    const { langPack } = LangStore()
    const { userData } = UserStore()

    const { writeContract: writeTransfer } = useNotifier({
        onSuccess() {
          closeModal();
        },
      });

      const transferhandler = async (user: IUser) => {
        writeTransfer({
            address: contracts.ContentNFT.address,
            abi: contracts.ContentNFT.abi,
            functionName: 'transferFrom',
            args: [userData?.address, user.address, activePost?.savva_cid]
        });
    };

  return (
    <div>
         <div className='text-[14px] text-[grey] flex w-full'>
               {langPack('nft.transfer_description')}
            </div>
            {activePost?.nft.on_market && 
                 <div className='text-[14px] text-[#cf4343] flex w-full mt-6'>
                    {langPack('nft.transfer_on_market_warning')}                  
                </div>
            }
            <div className='flex flex-row  items-end gap-4 mt-6'>
              <FindUser onSelect={transferhandler} title={langPack('nft.transfer_token')}/>
            </div>
    </div>
  )
}

export default TransferToken