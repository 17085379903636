import { checkConfig } from '@/app/api/get-default-connect-yaml'
import { pathToVersion } from '@/app/lib/hook/use-init-app'
import { ConfigStore, LangStore } from '@/shared/model/store'
import { AlertDialog, AlertDialogAction, AlertDialogContent, AlertDialogDescription, AlertDialogFooter, AlertDialogHeader, AlertDialogTitle } from '@/shared/ui'
import { useEffect, useState } from 'react'

const VersionModal = () => {
    const {langPack} = LangStore()
	const { version, setVersion } = ConfigStore()
	const [versionData, setVersionData] = useState<{version: number, ls_clean: string[]} | null>(null)

	useEffect(() => {
		const gets = async () => {
			if(versionData) return
			const res = await checkConfig(pathToVersion)

			setVersionData(res)
		}
		gets()
		setInterval( async () => {
			gets()
		}, 600000);
	}, [])
	
	const update = () => {
		setVersion({
			...version,
			reload: false
		})
		if(versionData?.ls_clean.length) {
			for(let i = 0; i < versionData.ls_clean.length; i++){
				localStorage.removeItem(versionData.ls_clean[i])
			}
		}
		window.location.reload()
	}

	if(!version.reload) return

  return (
    <AlertDialog open>
			<AlertDialogContent>
				<AlertDialogHeader>
					<AlertDialogTitle>{`We are updated to ${versionData?.version}`}</AlertDialogTitle>
					<AlertDialogDescription>
						<div>{langPack('default.update_text')}</div>
						<div className='mt-2'>{langPack('default.update_text_sec')}</div>
					</AlertDialogDescription>
				</AlertDialogHeader>
				<AlertDialogFooter>
					<AlertDialogAction onClick={update}>{langPack('action.update')}</AlertDialogAction>
				</AlertDialogFooter>
			</AlertDialogContent>
		</AlertDialog>
  )
}

export default VersionModal