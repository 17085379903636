import { UserStore } from "@/entities/user"
import { IUser } from "@/entities/user/model/types"
import { contractRead, stringToBytes32 } from "@/shared/lib"
import useRequest from "@/shared/lib/use-request"
import { ConfigStore } from "@/shared/model/store"
import { ContractsStore } from "@/shared/model/store/contracts-store"
import { null_address } from "@/shared/model/types"
import { useState } from "react"

const DEFAULT_CONFIG = {
	nft_auction_min_increment: 0,
	nft_auction_max_increment: 0,
	nft_auction_max_duration: 0,
	min_staked_for_nft_auction: 0,
	isApproval: false,
}

export const useAuctionConfig = () => {
    const [config, setConfig] = useState<typeof DEFAULT_CONFIG>(DEFAULT_CONFIG) 
    const [isLoading, setIsloading] = useState<boolean>(true)
	const { userData } = UserStore()
	const { contracts } = ContractsStore()
	const { domain } = ConfigStore()
	const { fetchRequest } = useRequest('getUser')

    const getConfig = async () => {
		setIsloading(true)
		const newConfig = DEFAULT_CONFIG
		newConfig.nft_auction_min_increment =
			Number((await contractRead('Config', 'getUInt', [stringToBytes32('nft_auction_min_increment')])) || 0)
		newConfig.nft_auction_max_increment =
			Number((await contractRead('Config', 'getUInt', [stringToBytes32('nft_auction_max_increment')])) || 0)
		newConfig.nft_auction_max_duration =
			Number((await contractRead('Config', 'getUInt', [stringToBytes32('nft_auction_max_duration')])) || 0)
		newConfig.min_staked_for_nft_auction =
			Number((await contractRead('Config', 'getUInt', [stringToBytes32('min_staked_for_nft_auction')])) || 0)
		const isApp =  await contractRead('ContentNFT', 'isApprovedForAll', [
			userData!.address || '0x00',
			contracts!.NFTAuction.address
		])
		newConfig.isApproval = Boolean(isApp)

		setConfig(newConfig)
		setIsloading(false)
	}

	const getAuctionData = async (cid: string) => {
        if(!cid) return
        const data = await contractRead('NFTAuction', 'auctions', [cid])

        const newPart = {
            on_auction: data[5],
            min_bid: Number(data[1]),
            highest_bid: Number(data[2]),
            auction_end_time: Number(data[4]),
			last_bid_contract: data[6] === contracts.SavvaToken.address ? '' : 'STAKED TOKENS'
        }

        let owner: IUser | null = null
        let bidder: IUser | null = null

		if(data[0] !== null_address) {
			owner  = await fetchRequest({
				params: {
					domain,
					user_addr: data[0]
				}
			})
        }
       

        if(data[3] !== null_address) {
            bidder  = await fetchRequest({
                params: {
                    domain,
                    user_addr: data[3]
                }
            })
        }


		return {
			...(owner && {owner}),
			bidder,
			...newPart
		}
    }
	

    return {
        isLoading,
        ...config,
        getConfig,
		getAuctionData,
    }
}