import { LangStore } from '@/shared/model/store'
import { Button, Dialog, DialogContent, DialogTrigger } from '@/shared/ui'
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/shared/ui/tabs'
import { useState } from 'react'
import RemoveToken from './remove'
import RewardsToken from './rewards'
import TransferToken from './transfer'
import MarketNft from './market'
import Auction from './auction'

const NftSettings = () => {
	const [open, setOpen] = useState(false)
	const [currentTab, setCurrentTab] = useState<string>('market')
	const { langPack } = LangStore()

	return (
		<Dialog onOpenChange={setOpen} open={open}>
			<DialogTrigger asChild>
				<Button className='w-full' size={'sm'}>
					{langPack('nft.settings')}
				</Button>
			</DialogTrigger>
			<DialogContent className='min-h-[320px] sm:max-w-[825px]'>
				<Tabs defaultValue='market' className='w-[99%]' value={currentTab} onValueChange={setCurrentTab}>
					<TabsList className='grid w-full grid-cols-5'>
						<TabsTrigger value='market'>{langPack('nft.market')}</TabsTrigger>
						<TabsTrigger value='auction'>
							{langPack('nft.auction')}
						</TabsTrigger>
						<TabsTrigger value='transfer'>{langPack('nft.transfer')}</TabsTrigger>
						<TabsTrigger value='rewards'>{langPack('nft.rewards')}</TabsTrigger>
						<TabsTrigger value='remove'>{langPack('nft.remove')}</TabsTrigger>
					</TabsList>
					<TabsContent value='market' className='p-2'>
						<MarketNft />
					</TabsContent>
					<TabsContent value='auction' className='p-2'>
						<Auction closeModal={() => setOpen(false)} active={currentTab === 'auction'}/>
					</TabsContent>
					<TabsContent value='transfer' className='p-2'>
						<TransferToken closeModal={() => setOpen(false)} />
					</TabsContent>
					<TabsContent value='rewards' className='p-2'>
						<RewardsToken />
					</TabsContent>
					<TabsContent value='remove' className='p-2'>
						<RemoveToken closeModal={() => setOpen(false)} />
					</TabsContent>
				</Tabs>
			</DialogContent>
		</Dialog>
	)
}

export default NftSettings
