import { IUser } from '@/entities/user/model/types';
import useRequest from '@/shared/lib/use-request';
import { useEffect, useState } from 'react'
import { useDebounce } from '../multi-select';
import { ConfigStore, LangStore } from '@/shared/model/store';
import { Label } from '../label';
import { Input } from '../input';
import { UserCard } from '@/entities/user';
import { RefreshCw } from 'lucide-react';
import { Button } from '../button';

type Props = {
    onSelect: (user: IUser) => void
    title: string
}

const FindUser = ({onSelect, title}: Props) => {
    const { domain } = ConfigStore()
    const { langPack } = LangStore()

    const { fetch, isLoading, result, dropState } = useRequest<IUser>('getUser');
    const [value, setValue] = useState<string>('');


    const debouncedValue = useDebounce(value, 500);

    const getUserProfile = () => {
        dropState()
        const userParam = debouncedValue && debouncedValue.startsWith("0x")
          ? "user_addr"
          : "user_name";
    
        fetch({
          params: {
            domain: domain,
            [userParam]: debouncedValue,
          },
        });
    };

    useEffect(() => {
        if (debouncedValue) {
          getUserProfile();
        } else {
            dropState();
        }
      }, [debouncedValue]);
    
    return (
        <div className='w-full'>
             <Label>{langPack('default.user_name')}</Label>
             <div className='bg-transparent'>
             <Input onChange={(e) => setValue(e.target.value)} value={value}/>
             <div className='h-[60px] flex flex-row items-center px-4 justify-between'>
                {isLoading ? <RefreshCw className="mr-2 h-4 w-4 animate-spin" /> : result?.address ? <UserCard user={result}/> : <div className='flex text-center text-[grey] text-[12px]'>{langPack('default.specify_user')}</div>}
                {result?.address && <Button size={'sm'} onClick={() => onSelect(result)}>{title}</Button>}
             </div>
             </div>
        </div>
    )
}

export default FindUser