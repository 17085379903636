import { UseFormReturn } from 'react-hook-form'
import { ContentBodyType, ITransformedLocales, RootDataType } from '../model/types'
import { formSchema } from '../model/model'
import { z } from 'zod'
import yaml from 'yaml'
import { cleanPreview } from '@/shared/lib'

export const NEW_POST_DIR = 'new-post'
export const NEW_COMMENT_DIR = 'content'

export const createPostBody = (
	commentData: RootDataType | null = null,
	address: `0x${string}`,
	domain: string,
	form: UseFormReturn<z.infer<typeof formSchema>>,
	pathToFolder: string
) => {
	const { locales, thumbnail, isNsfw } = form.getValues()
	const formData = new FormData()

	const transformedLocales = {}
	//FIXME: если в локали ничего нет то не отправлять
	for (const [key, locale] of Object.entries(locales)) {
		if (!locale.data) continue
		transformedLocales[key] = {
			tags: locale.tags.map(tag => tag.value),
			chapters: locale.chapters.slice(1).map(({ title }, idx) => ({
				title,
				data_path: `${key}/chapter_${idx + 1}.md`
			})),
			text_preview: cleanPreview(locale.data),
			data_path: locale.data ? `${key}/data.md` : '',
			title: locale.title,
			categories: locale.categories.map(el => el.value)
		} satisfies ITransformedLocales
	}

	
	//FIXME: Возможно не стоит приводить к формату а сохранять в том виде в котором есть
	// тогда не придется парсить при получении
	Object.entries(locales).forEach(([key, locale]) => {
		locale.chapters.slice(1).forEach(({ data }, idx) => {
			const chapter = new File([data!], `${key}/chapter_${idx + 1}.md`)
			formData.append('file', chapter, `/${pathToFolder}/${key}/chapter_${idx + 1}.md`)
		})

		const data = new File([locale.data!], `${key}/data.md`)
		formData.append('file', data, `/${pathToFolder}/${key}/data.md`)
	})

	// const DIR = commentData ? NEW_COMMENT_DIR : NEW_POST_DIR

	const mainBody: ContentBodyType = {
		savva_spec_version: '2.0',
		mime_type: 'text/markdown',
		encoding: 'utf-8',
		license: 'CC0',
		author: address,
		thumbnail,
		nsfw: isNsfw,
		locales: transformedLocales,
		...(commentData && { ...commentData })
	}

	const yml = yaml.stringify(mainBody)
	const blob = new Blob([yml], { type: 'text/markdown' });
  const info = new File([blob], `/${pathToFolder}/info.yaml`);

	formData.append('file', info, `/${pathToFolder}/info.yaml`)

	formData.append('domain', domain)
	return formData
}
