import { Logo } from '@/features/logo'
import { DEFAULT_PREC } from '@/shared/lib'
import { LangStore } from '@/shared/model/store'
import { Card } from '@/shared/ui'
import { AmountBlock } from '@/shared/ui/amount-block/amount-block'

type Props = {
    savva: number
    pls: number
    expDate: string | number
}

const PromoBox = ({savva, pls, expDate}: Props) => {
    const { langPack } = LangStore()

    return (
    <Card className='p-5 relative overflow-hidden bg-background'>
        <div className='flex flex-row justify-between items-center w-full border-b-black border-b-[2px] pb-4 mt-4'>
            <Logo />
            <div>
                {langPack('default.promo_code')}
            </div>
            
        </div>
        <div className='text-[grey] text-[14px] mt-4'>
            {langPack('profile.promo_info')}
        </div>
        <div className='flex flex-col mt-10 w-full justify-end items-end'>
            {Number(savva) > 0 && <div><AmountBlock amount={savva} size='lg' perUsd/></div>}
            {Number(pls) > 0 && <div><AmountBlock amount={pls} token='PLS' size='lg' perUsd/></div>}
        </div>
        <div className='flex flex-row justify-center w-full text-[grey] text-[14px] mt-6'>
            {`Experation date: ${expDate}`}
        </div>
        <div className='flex absolute scale-[9] opacity-[0.03] bottom-[80px] left-[170px]'>
            <Logo />
        </div>
    </Card>
  )
}

export default PromoBox