import { allowedAudios, allowedImages, allowedVideos } from '@/features/md-editor'
import { AppDetailStore } from '@/shared/model/store'
import { z } from 'zod'
import { currentTotalSize, isComment } from '../lib/utils'
import { DropzoneOptions } from 'react-dropzone'

const title = z.string().trim()
const data = z.string().trim()
const id = z.string()

const chapter = z.object({
	title,
	data,
	id
})

export const languageSchema = z.object({
	title,
	data,
	chapters: z.array(chapter),
	categories: z.array(z.object({ label: z.string(), value: z.string() })),
	tags: z.array(z.object({ label: z.string(), value: z.string() })),
	currentChapter: z.string().optional().default('')
})

export const formSchema = z
	.object({
		thumbnail: z.string(),
		files: z.array(z.any()).optional(),
		isRegisterNewPost: z.boolean().optional(),
		isNsfw: z.boolean().optional(),
		isComment: z.boolean().optional(),
		locales: z.record(languageSchema)
	})
	.superRefine(({ isComment, locales }, ctx) => {
		const isRequired = AppDetailStore.getState().appDetails.modules.categories.required

		const checkComment = Object.values(locales).some(locale => !!locale.data)

		Object.entries(locales).forEach(([lang, value]) => {
			const { title, data, categories, chapters } = value

			const checkTitleAndData = (!!title && !data) || (!title && !!data)

			if (!isComment && checkTitleAndData) {
				ctx.addIssue({
					code: z.ZodIssueCode.custom,
					path: [`locales.${lang}.${!title ? 'title' : 'data'}`]
				})
			}

			if (isComment && !checkComment) {
				ctx.addIssue({
					code: z.ZodIssueCode.custom,
					path: [`locales.${lang}.data`]
				})
			}

			if (chapters.length) {
				chapters.forEach((chapter, index) => {
					if (!chapter.title || !chapter.data) {
						ctx.addIssue({
							code: z.ZodIssueCode.custom,
							// message: `${!chapter.title ? 'title' : 'data'} is required in chapter ${index + 1}`,
							path: [`locales.${lang}.chapters.${index}.${!chapter.title ? 'title' : 'data'}`]
						})
					}
				})
			}

			const checkTitleAndData2 = (!!title && !data) || (!title && !!data) || (!!title && !!data)

			if (!isComment && isRequired && checkTitleAndData2 && !categories.length) {
				ctx.addIssue({
					code: z.ZodIssueCode.custom,
					message: `is required`,
					path: [`locales.${lang}.categories`]
				})
			}
		})
	})

export const dropzone = (files: File[], maxTotalSize: number) =>
	({
		accept: {
			'image/*': allowedImages,
			'video/*': allowedVideos,
			'audio/*': allowedAudios
		},
		maxFiles: 100,
		maxSize: maxTotalSize,
		multiple: true,
		validator: file => {
			if (currentTotalSize(files || []) + file.size > maxTotalSize) {
				return {
					code: 'total-size-exceeded',
					message: 'Превышен общий размер всех файлов'
				}
			} else {
				return null
			}
		}
	}) satisfies DropzoneOptions

export const newChapter = {
	title: '',
	data: '',
	id: Date.now().toString()
}

export const systemChapter = {
	title: '-- post --',
	data: '-- data --',
	id: Date.now().toString()
}

export const getDefaultValues = (contentLocales: string[], contentType: string) => ({
	files: [],
	thumbnail: '',
	locales: contentLocales.reduce((acc, locale) => {
		acc[locale] = { title: '', data: '', tags: [], chapters: [], categories: [] }
		return acc
	}, {}),
	isRegisterNewPost: false,
	isNsfw: false,
	isComment: isComment(contentType)
})

export const transformCategories = (locale: string) => {
	const categories = AppDetailStore().appDetails.modules.categories.locales[locale] ?? []
	return categories.map(el => {
		const [group, label] = el.split('/')
		return {
			group: group || '',
			label: label || group,
			value: el
		}
	})
}
