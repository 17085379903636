import { useEffect, useState } from 'react'
import { ProfileProps } from '../../profile'
import useRequest from '@/shared/lib/use-request';
import dayjs, { Dayjs } from 'dayjs';
import { LangStore } from '@/shared/model/store';
import { ILang } from '@/entities/feed-block/model/types';
import { IUser } from '@/entities/user/model/types';
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger, Badge, Card, Input, Preloader } from '@/shared/ui';
import { PlsIcon, SavvaIcon } from '@/shared/assets';
import { DEFAULT_PREC, getUTCTimeToShowStrShortDate, shortNumberFormat } from '@/shared/lib';
import { ArrowDown, ArrowUp, CircleX, ListFilter } from 'lucide-react';
import { UserCard } from '@/entities/user';
import { OPTIONS_HISTORY } from './utils';
import { Link } from 'react-router-dom';
import { fixLengthPreview, getCurrentTitles } from '@/entities/feed-block/model/utils';
import FormatNumber from '@/shared/ui/format-number';
import CascadeFilter from './filter';
import { ContractsStore } from '@/shared/model/store/contracts-store';
import { null_address } from '@/shared/model/types';

interface ITransaction {
    contract: string;
    domain: string;
    type: string;
    from: IUser;
    to: IUser;
    event_contract: string;
    event_id: string;
    amount: number;
    savva_cid: string;
    locales: ILang;
    time_stamp: string;
    token: string;
    tx_hash: string;
    info: string;
    info_user: IUser;
}

const system_user: IUser = {
    "address": "0xd871456a5d3420E67D72812100D53201E0329722" as `0x${string}`,
    "name": "firstvoice",
    "avatar": "system", // TODO SYSTEM AVATAR 
    "staked": 0n,
    "n_following": 2,
    "n_followers": 0,
    "n_sponsoring": 0,
    "n_sponsored": 0,
    "n_nfts": 0,
    "banned": false,
    "ban_comment": "",
    "profile_cid": "QmSanzemBQUGyZZseLXCBnbeNRPCEGNMu4bd1tFPMtXmtp",
    "display_name": "System",
    "chat_free_limit": 0,
    "chat_price": 0
}

type RangeValue = [Dayjs | null, Dayjs | null] | null

const RenderUser = ({item, lang, profile}: {item: ITransaction; lang: ILang, profile: IUser}) => {
    const { contracts } = ContractsStore()
    const isSenderContract = Object.keys(contracts).find(k => contracts[k].address === item.from?.address);
    if(isSenderContract && isSenderContract !== null_address ) {
      return (
        <div className='flex flex-row'>
        <UserCard
          user={{...system_user, display_name: isSenderContract}}
          descriprion={getUTCTimeToShowStrShortDate(item.time_stamp, lang)}
        />
      </div>
      );
    }
    if (!item.to || !item.from) {
      return (
        <div className='flex flex-row'>
        <UserCard
          user={system_user}
          descriprion={getUTCTimeToShowStrShortDate(item.time_stamp, lang)}
        />
      </div>
      );
    }
    return (
      <div className='flex flex-row'>
        <UserCard
          user={item.from.address === profile.address ? item.to : item.from}
          descriprion={getUTCTimeToShowStrShortDate(item.time_stamp, lang)}
        />
      </div>
    );
  };

const RenderDescription = ({item,  langpack}: {item: ITransaction; langpack: (s: string) => void}) => {
    const hist = OPTIONS_HISTORY(langpack)
    const idx = hist.findIndex(i => i.value === item.contract);
    const event = hist[idx]?.children?.find(i => i.value === item.type);

    return (
        <div className='flex flex-col justify-center'>
          <span style={{ textAlign: 'start' }} className='text-[grey] text-[12px]'>{`${hist[idx]?.label}: ${event?.label}`}</span>
          {item.type === 'redeem' && item.contract === 'promo' && <div className='text-[grey] text-[12px]'>{item.info}</div>}
          {item.contract === 'club' && item.info && <UserCard user={item.info_user} className='h-5 w-5'/>}
          {item.savva_cid && (
            <Link to={`/content/${item.savva_cid}`}>
              <div>
              {fixLengthPreview(
                    getCurrentTitles(
                        item.locales,
                        'title'
                    ),30
                )}
              </div>
            </Link>
          )}
        </div>
    )

}

const RenderAmount = ({obj, profile}: {obj: ITransaction, profile: IUser}) => {
    if(!obj.amount) return <></>
    if (obj.from?.address === profile?.address) {
      return (
        <div className='flex flex-row gap-4 justify-end'>
          <span style={{ color: 'red' }}>
            -
            <FormatNumber number={obj.amount} />
          </span>

          {!obj.token ? <PlsIcon /> : <SavvaIcon />}
        </div>
      );
    }
    return (
        <div className='flex flex-row gap-4 justify-end'>
        <span style={{ color: 'green' }}>
          +
          <FormatNumber number={obj.amount} />
        </span>
        {!obj.token ? <PlsIcon /> : <SavvaIcon />}
      </div>
    );
  };


const TransactionHistory = ({profile}: ProfileProps) => {
    const { lang, langPack } = LangStore()
    const { fetch: fetchHistory, isLoading } = useRequest('getHistory');
    const [transactionHistory, setTransactionHistory] = useState<ITransaction[]>([]);
    const [rangeDate ] = useState<RangeValue>([dayjs().add(-2, 'M'), dayjs()]);
    const [savvaCid, setSavvaCid] = useState<string | undefined>(undefined);
    const [selectOptions, setSelectOptions] = useState({ contracts: '', type: '' });

    const totalSentSavva = transactionHistory.reduce((acc, item) => {
        if (item.from?.address === profile?.address && item.token) {
          return acc + Number(item.amount);
        }
        return acc;
      }, 0);
    
      const totalGetSavva = transactionHistory.reduce((acc, item) => {
        if (item.to?.address === profile?.address && item.token) {
          return acc + Number(item.amount);
        }
        return acc;
      }, 0);
    
      const totalGetPls = transactionHistory.reduce((acc, item) => {
        if (item.to?.address === profile?.address && !item.token) {
          return acc + Number(item.amount);
        }
        return acc;
      }, 0);
    
      const totalSentPls = transactionHistory.reduce((acc, item) => {
        if (item.from?.address === profile?.address && !item.token) {
          return acc + Number(item.amount);
        }
        return acc;
      }, 0);

    const getStories = () => {
        /* const startDate = rangeDate
          ? dayjs(rangeDate[0]).add(1, 'day').startOf('day').subtract(1, 'second')
            .format('YYYY-MM-DDTHH:mm:ss[Z]')
          : ''; */
        const endDate = rangeDate
          ? dayjs(rangeDate[1]).add(1, 'day').startOf('day').subtract(1, 'second')
            .format('YYYY-MM-DDTHH:mm:ss[Z]')
          : '';
    
        fetchHistory({
          params: {
            // domain: domain,
            user: profile.address,
            contracts: selectOptions.contracts,
            savva_cid: savvaCid,
            type: selectOptions.type,
            // time_from: startDate,
            time_to: endDate,
          },
          onSuccess(res) {
            setTransactionHistory(res);
          },
        });
      };

      useEffect(() => {
        if (!profile?.address) return;
        getStories();
      // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [selectOptions, savvaCid, rangeDate, profile.address]);

  return (
    <div>
        <div className='flex flex-row gap-10 justify-between'>
            <Card className='flex flex-row gap-10 items-center w-[45%] justify-between px-6 py-4'>
                <SavvaIcon />
                <div className='flex flex-row gap-2 items-center'>
                    <ArrowDown  size={16} color='green'/>
                    <div className='font-bold text-[green] text-[24px]'>
                        {shortNumberFormat(totalGetSavva / DEFAULT_PREC)}
                    </div>
                </div>
                <div className='flex flex-row gap-2 items-center'>
                    <ArrowUp size={16} color='red'/>
                    <div className='font-bold text-[red] text-[24px]'>
                        {shortNumberFormat(totalSentSavva / DEFAULT_PREC)}
                    </div> 
                </div>
            </Card>
            <Card className='flex flex-row gap-10 items-center w-[45%] justify-between px-6 py-4'>
                <PlsIcon />
                <div className='flex flex-row gap-2 items-center'>
                    <ArrowDown  size={16} color='green'/>
                    <div className='font-bold text-[green] text-[24px]'>
                        {shortNumberFormat(totalGetPls / DEFAULT_PREC)}
                    </div>
                </div>
                <div className='flex flex-row gap-2 items-center'>
                    <ArrowUp size={16} color='red'/>
                    <div className='font-bold text-[red] text-[24px]'>
                        {shortNumberFormat(totalSentPls / DEFAULT_PREC)}
                    </div> 
                </div>
            </Card>
        </div>
      <div className='flex flex-col w-full my-5'>
        <Accordion type="single" collapsible className="w-full mb-2">
        <AccordionItem value="item-1">
          <AccordionTrigger>{langPack('default.filters')}</AccordionTrigger>
          <AccordionContent>
            <div>
              <div>
                <CascadeFilter setSelectOptions={setSelectOptions} />
              </div>
              <div className='flex flex-row items-center relative'>
                <Input
                  onChange={e => setSavvaCid(e.target.value)}
                  value={savvaCid}
                  placeholder={langPack('profile.history_input_placeholder_two')}
                />
                <div className='absolute right-[10px] cursor-pointer' onClick={() => setSavvaCid('')}>
                  <CircleX  size={20} color='grey'/>
                </div>
              </div> 
            </div>
          </AccordionContent>
        </AccordionItem>
      </Accordion>
      </div>
      <div className=''>
        {isLoading ? <Preloader small />:
            transactionHistory.map((el: ITransaction) =>(
                <div className='mb-6 border-b-[1px] pb-6 grid grid-cols-6 grid-rows-1 gap-4'>
                    <div className="col-span-2"><RenderUser item={el} profile={profile} lang={lang}/></div>
                    <div className="col-span-2 col-start-3"><RenderDescription item={el} langpack={langPack} /></div>
                    <div className="col-start-5 flex flex-row gap-4 items-center">
                      <a href={`https://scan.9inch.io/#/tx/${el.tx_hash}`} target='_blank'>
                        <Badge className='rounded-sm'>TRX</Badge>
                      </a>
                      {
                      el.savva_cid 
                      ? <div onClick={() => setSavvaCid(el.savva_cid)} className='flex flex-row items-center cursor-pointer'><ListFilter size={16}/></div>
                      : null
                    }</div>
                    <div className="col-start-6"><RenderAmount obj={el} profile={profile}/></div>  
                </div>
            ))
        }
       </div>
    </div>
  )
}

export default TransactionHistory