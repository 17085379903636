import { ContentStore } from '@/entities/feed-block/model/content-store'
import { DAY_IN_SECONDS, DEFAULT_PREC, parceEthAmount, parseAmount } from '@/shared/lib'
import useNotifier from '@/shared/lib/use-notifier'
import { LangStore } from '@/shared/model/store'
import { ContractsStore } from '@/shared/model/store/contracts-store'
import { Button } from '@/shared/ui'
import AmountInput from '@/shared/ui/amount-input/ui/amount-input'
import { RefreshCw } from 'lucide-react'
import { useEffect, useState } from 'react'
import { useAuctionConfig } from '../auction/use-auction'
import { useWallet } from '@/entities/wallet'

interface Props {
	closeModal: () => void
	active: boolean
}

const Auction = ({ closeModal, active }: Props) => {
	const { activePost } = ContentStore()
	const nft = activePost?.nft
	const { contracts } = ContractsStore()
	const [duration, setDuration] = useState<number>(7)
	const [startPrice, setStartPrice] = useState<number>(100)
	const { getConfig, isLoading, nft_auction_max_duration, nft_auction_max_increment, nft_auction_min_increment, isApproval, min_staked_for_nft_auction} = useAuctionConfig()
	const { stakedBalance } = useWallet()

	const { langPack } = LangStore()

	const { writeContract, isLoading: isLoadingContract } = useNotifier({
		onSuccess() {
			closeModal()
		}
	})

	const { writeContract: writeApproval, isLoading: isLoadingContractApproval } = useNotifier({
		onSuccess() {
			getConfig()
		}
	})

	const createAuction = () => {
		writeContract({
			address: contracts.NFTAuction.address,
			abi: contracts.NFTAuction.abi,
			functionName: 'createAuction',
			args: [activePost?.savva_cid, parseAmount(startPrice), duration * DAY_IN_SECONDS]
		})
	}

	const setApprovalForAll = async () => {
		writeApproval({
			address: contracts.ContentNFT.address,
			abi: contracts.ContentNFT.abi,
			functionName: 'setApprovalForAll',
			args: [contracts!.NFTAuction.address, true]
		})
	}

	useEffect(() => {
		if(active) {
			getConfig()
		}
	}, [active])

	const lowStake = stakedBalance < Number(min_staked_for_nft_auction) / DEFAULT_PREC
	const disabled = lowStake || nft?.on_market || isLoading || isLoadingContractApproval

	return (
		<div>
			{nft?.on_market && <div className='text-[red] mb-2'>{langPack('nft.need_remove_from_market')}</div>}
			{lowStake && <div className='text-[red] mb-2'>{langPack('nft.low_stake')} {parceEthAmount(min_staked_for_nft_auction)}</div>}
			<div className='flex w-full text-[14px] text-[grey]'>{langPack('nft.auction_title')}</div>
			<div className='flex w-full fkex-row gap-4 text-[14px] text-[grey] mt-4'>
					<div className='flex flex-row items-center gap-1'>{`${langPack('nft.min_increment')}:`} {isLoading ? <RefreshCw className="mr-2 h-4 w-4 animate-spin" /> : `${nft_auction_min_increment}%`}</div>
					<div className='flex flex-row items-center gap-1'>{`${langPack('nft.max_increment')}:`} {isLoading ? <RefreshCw className="mr-2 h-4 w-4 animate-spin" /> : `${nft_auction_max_increment}%`}</div>
			</div>
			<div className='flex flex-row mt-4 justify-between'>
				<div className='flex flex-col w-[48%]'>
				<div className='text-[14px] text-[grey]'>{`${langPack('nft.duration_label')} ${nft_auction_max_duration / DAY_IN_SECONDS}`}</div>
					<AmountInput noDigits value={duration} onChange={setDuration} perUsd={false} max={nft_auction_max_duration / DAY_IN_SECONDS} disabled={disabled}/>
				</div>
				<div className='flex flex-col w-[48%]'>
					<div className='text-[14px] text-[grey]'>{`${langPack('nft.start_price')}`}</div>
					<AmountInput value={startPrice} onChange={setStartPrice} token='SAVVA' disabled={disabled}/>
				</div>
			</div>
			<div className='flex w-full mt-4'>
				<Button
					className='w-full'
					onClick={!isApproval ? setApprovalForAll : createAuction}
					loading={isLoadingContract || isLoadingContractApproval}
					disabled={disabled}
				>
					{!isApproval ? langPack('nft.allow') : langPack('nft.start_auction')}
				</Button>
			</div>
		</div>
	)
}

export default Auction
