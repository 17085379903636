import { ContentStore } from '@/entities/feed-block/model/content-store'
import { UserStore } from '@/entities/user'
import { contractRead, parseAmount } from '@/shared/lib'
import useNotifier from '@/shared/lib/use-notifier'
import { LangStore } from '@/shared/model/store'
import { ContractsStore } from '@/shared/model/store/contracts-store'
import { Button } from '@/shared/ui'
import AmountInput from '@/shared/ui/amount-input/ui/amount-input'
import { useCallback, useEffect, useState } from 'react'

const MarketNft = () => {
	const { langPack } = LangStore()
	const { activePost } = ContentStore()
	const { userData } = UserStore()
	const { contracts } = ContractsStore()
	const [isApprovalForState, seIsApprovalForState] = useState(false)
	const [quantity, setQuantity] = useState<number | undefined>(undefined)

	const isApprovalForAll = useCallback(async () => {
		const state = await contractRead('ContentNFT', 'isApprovedForAll', [
			userData!.address || '0x00',
			contracts!.NFTMarketplace.address
		])
		seIsApprovalForState(Boolean(state))
	}, [userData, contracts])

	const { writeContract: writeContractApproval, isLoading } = useNotifier({
		onSuccess() {
			isApprovalForAll()
		}
	})

	const { writeContract: writeAddToMarket, isLoading: isLoadingWriteAddToMarket } = useNotifier({
		onSuccess() {
			setQuantity(undefined)
		}
	})

	const { writeContract: writeChangePrice, isLoading: isLoadingChangePrice } = useNotifier({
		onSuccess() {
			setQuantity(undefined)
		}
	})

	const { writeContract: writeRemoveFromMarket, isLoading: isLoadingRemoveFromMarket } = useNotifier({
		onSuccess() {
			setQuantity(undefined)
		}
	})

	const loading = isLoading || isLoadingWriteAddToMarket || isLoadingChangePrice || isLoadingRemoveFromMarket

	const setApprovalForAll = async () => {
		writeContractApproval({
			address: contracts.ContentNFT.address,
			abi: contracts.ContentNFT.abi,
			functionName: 'setApprovalForAll',
			args: [contracts!.NFTMarketplace.address, true]
		})
	}

	const addToMarket = async () => {
		writeAddToMarket({
			address: contracts.NFTMarketplace.address,
			abi: contracts.NFTMarketplace.abi,
			functionName: 'addToMarket',
			args: [activePost?.savva_cid, parseAmount(quantity || 0)]
		})
	}

	const removeFromMarket = async () => {
		writeRemoveFromMarket({
			address: contracts.NFTMarketplace.address,
			abi: contracts.NFTMarketplace.abi,
			functionName: 'removeFromMarket',
			args: [activePost?.savva_cid]
		})
	}

	const changePrice = async () => {
		writeChangePrice({
			address: contracts.NFTMarketplace.address,
			abi: contracts.NFTMarketplace.abi,
			functionName: 'changePrice',
			args: [activePost?.savva_cid, parseAmount(quantity || 0)]
		})
	}

	useEffect(() => {
		isApprovalForAll()
	}, [])

	return (
		<div>
			<div className='flex w-full text-[14px] text-[grey]'>{langPack('nft.market_title')}</div>
			{!isApprovalForState ? (
				<div className='mt-4 flex flex-row items-center justify-between'>
					<div className='text-[12px] text-[grey]'>{langPack('nft.add_marketplace_info')}</div>
					<Button size='sm' onClick={setApprovalForAll} loading={loading}>
						{langPack('nft.allow')}
					</Button>
				</div>
			) : (
				<>
					{!activePost?.nft.on_market ? (
						<div className='mt-4'>
							{activePost?.nft.on_auction && <div className='text-[red] mb-2'>{langPack('nft.need_remove_from_auction')}</div>}
							<div className='mt-6 flex w-full text-[14px] text-[grey]'>{langPack('nft.market_set_price')}</div>
							<div className='flex flex-row items-center gap-4 mt-4'>
								<div className='w-[320px]'>
									<AmountInput value={quantity} onChange={setQuantity} disabled={activePost?.nft.on_auction}/>
								</div>
								<div>
									<Button onClick={addToMarket} loading={loading} disabled={activePost?.nft.on_auction}>
										{langPack('nft.add_marketplace')}
									</Button>
								</div>
							</div>
						</div>
					) : (
						<div>
							<div className='mt-6 flex w-full text-[14px] text-[grey]'>{langPack('nft.market_new_price')}</div>
							<div className='flex w-full flex-row items-center gap-4'>
								<div className='w-[320px]'>
									<AmountInput value={quantity} onChange={setQuantity} />
								</div>
								<div className='flex flex-row gap-4'>
									<Button onClick={changePrice} loading={loading}>
										{langPack('nft.save_button')}
									</Button>
									<Button variant='destructive' loading={loading} onClick={removeFromMarket}>
										{langPack('nft.remove_from_market')}
									</Button>
								</div>
							</div>
						</div>
					)}
				</>
			)}
		</div>
	)
}

export default MarketNft
