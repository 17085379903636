import { UserStore } from '@/entities/user'
import { toast } from '@/shared/lib'
import useRequest from '@/shared/lib/use-request'
import { AppInfoStore, ConfigStore, LangStore } from '@/shared/model/store'
import { Button } from '@/shared/ui'
import { getContentFromHash } from '@/widgets/post-editor/lib/utils'
import { useChainModal, useConnectModal } from '@rainbow-me/rainbowkit'
import React from 'react'
import { useAccount, useSignMessage } from 'wagmi'

type ButtonProps = Pick<React.ButtonHTMLAttributes<HTMLButtonElement>, 'type' | 'className' | 'onClick'>

type VariantType = 'default' | 'orange' | 'outline' | 'ghost' | 'outline' | 'orange' | 'semi' | 'secondary' | 'link'

type Props = ButtonProps & {
	title: string | JSX.Element
	requireWallet?: boolean
	requireAuth?: boolean
	isLoading?: boolean
	disabled?: boolean
	varinat?: VariantType
	classname?: string
	size?: "default" | "sm" | "lg" | "icon" | null | undefined
}

export const GuardButton: React.FC<Props> = ({
	title,
	onClick,
	requireWallet = true,
	requireAuth = false,
	isLoading = false,
	disabled = false,
	varinat = 'default',
	classname,
	size = 'default',
	...props
}) => {
	const { address } = useAccount()
	const { openConnectModal } = useConnectModal()
	const { chain } = useAccount()
	const { openChainModal } = useChainModal()
	const { signatureAddress, setSignatureAddress, setUserData } = UserStore()
	const { domain } = ConfigStore()
	const { langPack } = LangStore()
	const message = AppInfoStore(state => state.appInfo.auth_text_to_sign)

	const fetchAuth = useRequest('auth')
	const fetchUser = useRequest('getUser')

	const { signMessageAsync } = useSignMessage({
		mutation: {
			onSuccess: async data => {
				await siweMessage(data)
			}
		}
	})

	const siwe = async () => (!chain && openChainModal ? openChainModal() : signMessageAsync({ message }))

	const siweMessage = async (signature: string) => {
		if (!address) return

		await fetchAuth.fetch({
			params: {
				user_addr: address,
				signature
			},
			onSuccess: async () => {
				await getUser()
			}
		})
	}

	const getUser = async () => {
		await fetchUser.fetch({
			params: {
				domain,
				user_addr: address
			},
			onSuccess: async res => {
				const userProfile = await getContentFromHash(res.profile_cid)
				const parced = userProfile ? JSON.parse(userProfile) : {}
				setUserData({ ...res, ...parced })
				setSignatureAddress(res.address)
			}
		})
	}

	const handleClick = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
		if(address && signatureAddress && address !== signatureAddress) {
			toast({
				key: 'imageLoad',
				variant: 'destructive',
				title: 'ERROR',
				description: langPack('wallet.wrong_info')
			})
			return
		}


		if (requireWallet && !address && openConnectModal) return openConnectModal()

		if ((requireAuth && !signatureAddress) || !address) {
			if (!address && openConnectModal) {
				return openConnectModal()
			} else {
				return siwe()
			}
		}

		if (onClick) {
			onClick(event)
		}
	}

	return (
		<Button
			className={classname}
			variant={varinat}
			loading={isLoading}
			onClick={handleClick}
			disabled={disabled}
			size={size}
			{...props}
		>
			{title}
		</Button>
	)
}
