import { RefreshCw } from 'lucide-react'
import { cn } from '../lib'
import { LangStore } from '../model/store'

export const PreloaderRound = ({ small = false }) => {
	const { langPack } = LangStore()
	return (
	<div
		className={cn(
			small ? 'flex items-center justify-center' : 'fixed inset-0 z-[1000] flex items-center justify-center',
			'bg-transparent'
		)}
	>
		<div className='flex flex-col gap-2 items-center'>
			<RefreshCw className="mr-2 h-24 w-24 animate-spin opacity-50" />
			<div className='font-[24px] animate-pulse'>{langPack('action.loading')}...</div>
		</div>
	</div>
)}
