import { AppInfoStore } from '@/shared/model/store'
import { ContentType } from '@/entities/feed-block/model/types'
import { getCurrentPostImage } from '@/entities/feed-block/model/utils'

type Props = {
    content: ContentType
    show: boolean
}

const ContentImage = ({content, show = true}: Props) => {
  const { appInfo } = AppInfoStore()  
  
  const imageSrc = getCurrentPostImage(content, appInfo)
  
  if(!show) return (
    <div className="h-[190px] w-full object-cover md:w-60 bg-[#fff] uppercase relative">
      <div className='h-[33%] bg-[black] text-[#fff] tracking-[10px] text-[35px] font-bold font-sans flex items-center justify-center'>
        parental  
      </div>
      <div className='h-[34%] text-[#000] tracking-[5px] text-[45px] font-bold flex items-center justify-center scale-y-[1.4] font-roboto '>
        advisory
      </div>
      <div className='h-[33%] bg-[black] text-[#fff]  flex items-center justify-center font-sans w-full'>
       <span className='scale-y-[1.4] scale-x-[0.8] text-[25px] font-bold tracking-[6px] w-full whitespace-nowrap -ml-5'>explicit content</span>
      </div>
    </div>
  )

  return (
    <img
        className="h-[190px] w-full object-cover md:w-60"
        src={imageSrc}
        alt="Event image"
    />
  )
}

export default ContentImage;