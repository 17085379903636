import { Button, Card, Preloader, ScrollArea } from '@/shared/ui'
import { useMessages } from '../lib/hooks/use-messages'
import { Message } from './message'
import { ChatStore } from '@/entities/chat'
import { FC, Fragment, useEffect, useRef, useState } from 'react'
import { ConfigStore } from '@/shared/model/store'
import { ArrowDown } from 'lucide-react'
import { cn, toast } from '@/shared/lib'
import { DateMessages } from './date-messages'
import useRequest from '@/shared/lib/use-request'
import { IMessage } from '../model/types'
import { UserStore } from '@/entities/user'
import { Permission } from './permission'
import { useBannedUsersList } from '../lib/hooks/use-banned-users-list'
import LastReadBagde from './last-read-bagde'

type Props = {
	mySponsorship: number
}

const BASE_COUNT = 50
const HALF_COUNT = BASE_COUNT / 2
//FIXME: отображать последнее сообщение в списке
export const Messages: FC<Props> = ({ mySponsorship }) => {
	const scrollRef = useRef<HTMLDivElement | null>(null)
	const [isAtBottom, setIsAtBottom] = useState(true)
	const [messages, setMessages] = useState<IMessage[]>([])
	const [fetchingNext, setFetchingNext] = useState(false)
	const [fetchingPrev, setFetchingPrev] = useState(false)
	const [showBlocked, setShowBlocked] = useState(false)
	const [isFirstOpen, setIsFirstOpen] = useState(true)
	const [focused, setFocused] = useState<number>(0)

	const { signatureAddress } = UserStore()
	const { lastRead, activeChat } = ChatStore()
	const { domain } = ConfigStore()

	const { newMessage, updatedMessage, userBanned, userUnbanned } = useMessages()
	const { bannedUserList, getBannedUsersList } = useBannedUsersList(domain, activeChat.chat_id)

	const { fetch, isLoading } = useRequest('getChat')

	const chatKey: string = `${activeChat.chat_id}:${domain}`
	const lastMessage: number = lastRead[chatKey]?.n || 0
	const lastReadMessage: number = lastRead[chatKey]?.lastRead || 0

	const getMessages = (from: number, limit: number, isNext: boolean) => {
		fetch({
			params: {
				domain,
				'chat-id': activeChat.chat_id,
				...(!!from && { 'n-from': from }),
				limit
			},
			onSuccess(res) {
				const fistMessage = messages[0]?.n || 0
				setMessages(prev => (isNext ? [...prev, ...res.list] : [...res.list, ...prev]))
				isNext ? setFetchingNext(false) : setFetchingPrev(false)
				if(isFirstOpen) {
					setFocused(lastReadMessage)
					setIsFirstOpen(false)
				}
				if(!isNext && !isFirstOpen) {
					setFocused(fistMessage)
				}
			},
			onError() {
				toast({ title: 'Error', variant: 'destructive' })
			}
		})
	}

	useEffect(() => {
		setIsFirstOpen(true)
		setIsAtBottom(true)
		setMessages([])

		if(!activeChat?.chat_id) return
		
		setFocused(lastReadMessage)
		const from = lastReadMessage < HALF_COUNT ? 0 : lastReadMessage - HALF_COUNT
		getMessages(from, BASE_COUNT, true)
	}, [activeChat.chat_id])

	const handleScroll = () => {
		if(isLoading || !scrollRef?.current) return
        const { scrollTop, scrollHeight, clientHeight } = scrollRef?.current;
		
		setIsAtBottom(scrollHeight - scrollTop < clientHeight + 300)

		const isCreated = messages.find((el: IMessage) => el.id === newMessage?.id)
		const bottomPosition = scrollHeight - scrollTop < clientHeight + 5
		
		if(
			bottomPosition
			&& !isCreated
			&& messages.at(-1).n < lastMessage
		) {
			setFetchingNext(true)
		} else if (scrollTop === 0 && messages[0]?.n !== 1) {
			setFetchingPrev(true)
		}
    };

	const scrollToBottom = () => {
		if (!scrollRef.current) return

		scrollRef.current.scrollTo({
			top: scrollRef.current.scrollHeight,
			behavior: 'smooth'
		})
	}

	useEffect(() => {
		if (!fetchingNext || !activeChat.chat_id || !messages.length) return
		const from = messages.at(-1).n + 1
		getMessages(from, BASE_COUNT, true)
	}, [fetchingNext, activeChat.chat_id])
	
	useEffect(() => {
		if (!fetchingPrev || !activeChat.chat_id || !messages.length) return
		
		const n_from = messages[0].n > 50 ? messages[0]?.n - 50 : 0
		const limit =
			messages[0].n > 50
				? 50
				: messages[0].n  - n_from
		
		getMessages(n_from, limit - 1, false)
	}, [fetchingPrev, activeChat.chat_id])

	useEffect(() => {
		if (!newMessage || newMessage.chat_id !== activeChat.chat_id) return
		
		setMessages(prev => [...prev, newMessage])
				
		if (newMessage.author.address === signatureAddress) {
			const timeoutId = setTimeout(() => {
				scrollToBottom()
			}, 100)

			return () => clearTimeout(timeoutId)
		}

	}, [newMessage])

	useEffect(() => {
		if(!userUnbanned?.chat_id || userUnbanned.chat_id !== activeChat.chat_id) return
		getBannedUsersList()
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [userUnbanned])

	useEffect(() => {
		if(!userBanned?.chat_id || userBanned.chat_id !== activeChat.chat_id) return
		getBannedUsersList()
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [userBanned])

	useEffect(() => {
		if (!updatedMessage) return

		setMessages(prev =>
			prev.map(message => (message.n === updatedMessage.n ? { ...message, ...updatedMessage } : message))
		)
	}, [updatedMessage])

	useEffect(() => {
		if(!messages.length || !activeChat.chat_id || !scrollRef?.current) return
		const { scrollTop, scrollHeight, clientHeight } = scrollRef?.current;

		setIsAtBottom((scrollHeight - scrollTop < clientHeight) || scrollHeight === clientHeight)
	},[messages])

	if (activeChat.chat_id !== signatureAddress && mySponsorship < activeChat?.author?.chat_read_price) {
		return <Permission chat_read_price={activeChat?.author?.chat_read_price} />
	}

	if(isLoading && isFirstOpen) {
		return <div className='h-full w-full flex flex-col items-center justify-center'><Preloader small/></div>
	}

	if(!messages.length) {
		return <div className='h-full w-full flex flex-col items-center justify-center'>
			<Card className='p-6'>
				Welcome to my chat
			</Card>
		</div>
	}

	return (
		<ScrollArea ref={scrollRef} onScrollCapture={handleScroll} className='relative'>
			{isLoading && <div className='h-[50px] w-full flex flex-col items-center justify-center opacity-35'><Preloader small/></div>}
			{messages.length > 0 &&
				messages.map((message, idx) => {
					const previousMessage = idx > 0 ? messages[idx - 1] : undefined
					return (
						<Fragment>
							<DateMessages message={message} previousMessage={previousMessage} />
							<Message
								key={message.n}
								setShowBlocked={setShowBlocked}
								showBlocked={showBlocked}
								bannedUserList={bannedUserList}
								isFocused={focused === message.n}
								message={message}
								isNextUserDifferent={messages[idx + 1]?.author.address !== message.author.address}
								className={cn(
									'message-element flex items-end gap-2',
									message.author.address === signatureAddress ? 'justify-end' : 'justify-start',
									idx !== messages.length - 1 && messages[idx + 1]?.author.address !== message.author.address && 'mb-10'
								)}
							/>
						</Fragment>
				)
			})}
			{isLoading && <div className='h-[50px] w-full flex flex-col items-center justify-center opacity-35'><Preloader small/></div>}
			<div className='h-3' />
			<Button onClick={scrollToBottom} className={cn('absolute bottom-2 right-5 block w-max', isAtBottom && 'hidden')} size={'sm'}>
					<div className='flex flex-col items-center absolute m-auto -top-2'>
						<LastReadBagde address={activeChat.chat_id}/>
					</div>
				<ArrowDown />
			</Button>
		</ScrollArea>
	)
}
