export const nft_mock = {
    "on_market": false,
    "price": 0,
    "owner": {
        "address": "0xd871456a5d3420E67D72812100D53201E0329722",
        "name": "firstvoice",
        "avatar": "QmUNtMhUeX6TzuGn66UqQCi3xiUzmncj6hYLNcGMvFyifb?filename=.png",
        "staked": 1.7600091509280037e+26,
        "n_following": 6,
        "n_followers": 5,
        "n_sponsoring": 3,
        "n_sponsored": 0,
        "total_sponsoring": 800000000000000000000,
        "total_sponsored": 0,
        "n_nfts": 0,
        "banned": false,
        "ban_comment": "",
        "profile_cid": "QmaC32CWVsSUuU9PmLqXZusEAovjAuPuTQGY8YszgVjLgw",
        "display_name": "Голосящий КиВиН с длинным именем, оооооооочень",
        "chat_read_price": 0,
        "chat_write_price": 0
    },
    "on_auction": true,
    "min_bid": 10000,
    "highest_bid": 2080880,
    "auction_end_time": 1738198310
}

export const user_mock = {
    "address": "0xd871456a5d3420E67D72812100D53201E0329722",
    "name": "firstvoice",
    "avatar": "QmUNtMhUeX6TzuGn66UqQCi3xiUzmncj6hYLNcGMvFyifb?filename=.png",
    "staked": 1.7600091509280037e+26,
    "n_following": 6,
    "n_followers": 5,
    "n_sponsoring": 3,
    "n_sponsored": 0,
    "total_sponsoring": 800000000000000000000,
    "total_sponsored": 0,
    "n_nfts": 0,
    "banned": false,
    "ban_comment": "",
    "profile_cid": "QmaC32CWVsSUuU9PmLqXZusEAovjAuPuTQGY8YszgVjLgw",
    "display_name": "Голосящий КиВиН с длинным именем, оооооооочень",
    "chat_read_price": 0,
    "chat_write_price": 0
}

export const null_address = '0x0000000000000000000000000000000000000000'

export const bid_event = {
    "type": "nft_auction_bid",
    "domain": "savva.app",
    "error": "",
    "data": {
        "content_id": "0x1c34ea53a30bd6cca73c77440c23ff3fcd2c26017696776cab1c0b6f4d94b6dd",
        "title": {
            "ru": "New post for auct"
        },
        "author": {
            "address": "0xd871456a5d3420E67D72812100D53201E0329722",
            "name": "firstvoice",
            "avatar": "QmUNtMhUeX6TzuGn66UqQCi3xiUzmncj6hYLNcGMvFyifb?filename=.png",
            "staked": 1.7600091509280037e+26,
            "n_following": 6,
            "n_followers": 5,
            "n_sponsoring": 3,
            "n_sponsored": 0,
            "total_sponsoring": 800000000000000000000,
            "total_sponsored": 0,
            "n_nfts": 0,
            "banned": false,
            "ban_comment": "",
            "profile_cid": "QmaC32CWVsSUuU9PmLqXZusEAovjAuPuTQGY8YszgVjLgw",
            "display_name": "Голосящий КиВиН с длинным именем, оооооооочень",
            "chat_read_price": 0,
            "chat_write_price": 0
        },
        "owner": {
            "address": "0xd871456a5d3420E67D72812100D53201E0329722",
            "name": "firstvoice",
            "avatar": "QmUNtMhUeX6TzuGn66UqQCi3xiUzmncj6hYLNcGMvFyifb?filename=.png",
            "staked": 1.7600091509280037e+26,
            "n_following": 6,
            "n_followers": 5,
            "n_sponsoring": 3,
            "n_sponsored": 0,
            "total_sponsoring": 800000000000000000000,
            "total_sponsored": 0,
            "n_nfts": 0,
            "banned": false,
            "ban_comment": "",
            "profile_cid": "QmaC32CWVsSUuU9PmLqXZusEAovjAuPuTQGY8YszgVjLgw",
            "display_name": "Голосящий КиВиН с длинным именем, оооооооочень",
            "chat_read_price": 0,
            "chat_write_price": 0
        },
        "bidder": {
            "address": "0xd871456a5d3420E67D72812100D53201E0329722",
            "name": "firstvoice",
            "avatar": "QmUNtMhUeX6TzuGn66UqQCi3xiUzmncj6hYLNcGMvFyifb?filename=.png",
            "staked": 1.7600091509280037e+26,
            "n_following": 6,
            "n_followers": 5,
            "n_sponsoring": 3,
            "n_sponsored": 0,
            "total_sponsoring": 800000000000000000000,
            "total_sponsored": 0,
            "n_nfts": 0,
            "banned": false,
            "ban_comment": "",
            "profile_cid": "QmaC32CWVsSUuU9PmLqXZusEAovjAuPuTQGY8YszgVjLgw",
            "display_name": "Голосящий КиВиН с длинным именем, оооооооочень",
            "chat_read_price": 0,
            "chat_write_price": 0
        },
        "price": 100000000000000000000,
        "token": "SAVVA"
    }
}

export const nft_auction_created = {
    "type": "nft_auction_created",
    "domain": "savva.app",
    "error": "",
    "data": {
        "content_id": "0x91508940b510fc7bb905786c38c3edd6066fd0fbd0c6f37f76718a7e429dff92",
        "title": {
            "en": "Туцфывлфыждалыв"
        },
        "author": {
            "address": "0xd871456a5d3420E67D72812100D53201E0329722",
            "name": "firstvoice",
            "avatar": "QmUNtMhUeX6TzuGn66UqQCi3xiUzmncj6hYLNcGMvFyifb?filename=.png",
            "staked": 1.7600091509280037e+26,
            "n_following": 6,
            "n_followers": 5,
            "n_sponsoring": 3,
            "n_sponsored": 0,
            "total_sponsoring": 800000000000000000000,
            "total_sponsored": 0,
            "n_nfts": 0,
            "banned": false,
            "ban_comment": "",
            "profile_cid": "QmaC32CWVsSUuU9PmLqXZusEAovjAuPuTQGY8YszgVjLgw",
            "display_name": "Голосящий КиВиН с длинным именем, оооооооочень",
            "chat_read_price": 0,
            "chat_write_price": 0
        },
        "min_price": 100000000000000000000,
        "end_time": 1738859999
    }
}