import { PreloaderRound } from '@/shared/ui/preloader-round'
import React, { useEffect, useRef } from 'react'

type Props = {
	children: React.ReactNode
	sidebar: React.ReactNode
	loading?: boolean
}

export const LayoutSidebar = ({ children, sidebar, loading }: Props) => {
	const sidebarRef = useRef<HTMLDivElement>(null)
	const contentRef = useRef<HTMLDivElement>(null)

	const handleScroll = () => {
		if (!sidebarRef.current || !contentRef.current) return
		const scrollTop = window.scrollY
		const viewportHeight = window.innerHeight
		const contentHeight = contentRef.current.getBoundingClientRect().height
		const sideBarTop = sidebarRef.current.getBoundingClientRect().top + window.scrollY

		if (scrollTop >= contentHeight - viewportHeight + sideBarTop) {
			contentRef.current.style.transform = `translateY(-${contentHeight - viewportHeight + sideBarTop}px)`
			contentRef.current.style.position = 'fixed'
			contentRef.current.style.width = `${sidebarRef.current.offsetWidth}px`;
		} else {
			contentRef.current.style.transform = ''
			contentRef.current.style.position = ''
		}
	}

	useEffect(() => {
		window.addEventListener('scroll', handleScroll)

		return () => {
			window.removeEventListener('scroll', handleScroll)
		}
	}, [])

	if(loading) return <div><PreloaderRound /></div>

	return (
		<div className='grid h-full w-full grid-cols-4 grid-rows-1 gap-4'>
			<div className='col-span-4 lg:col-span-3'>{children}</div>
			<div className='lg:col-span-1 lg:inline-block' ref={sidebarRef}>
				<div ref={contentRef}>{sidebar}</div>
			</div>
		</div>
	)
}
