import { ContentStore } from '@/entities/feed-block/model/content-store'
import { Fund } from '@/entities/feed-block/model/types'
import { SavvaIcon } from '@/shared/assets'
import { contractRead, stringToBytes32 } from '@/shared/lib'
import { LangStore } from '@/shared/model/store'
import { Card, CardContent, CardTitle, Preloader } from '@/shared/ui'
import { AmountBlock } from '@/shared/ui/amount-block/amount-block'
import { CountdownTimer } from '@/shared/ui/countdown-timer'
import { useCallback, useEffect, useState } from 'react'
import ContributeModal from './contribute-modal'

export const PositiveFundBox = () => {
	const { activePost, setActivePost } = ContentStore()

	const { langPack } = LangStore()

	const [finish, setFinish] = useState<boolean>(Number(activePost?.fund.round_time) * 1000 < Date.now() || false)
	const [isLoading, setIsLoading] = useState<boolean>(true)
	const [config, setconfig] = useState({
		minFundToShare: 0,
		rewardPercent: 0
	})

	const currentFund = activePost?.fund.amount || 0

	const nextReward =
		Number(config.minFundToShare) < Number(currentFund)
			? (Number(currentFund) / 100) * Number(config.rewardPercent)
			: Number(currentFund)

	const getFundData = useCallback(async () => {
		if (!activePost?.savva_cid) return
		setIsLoading(true)
		const fund: Fund = await contractRead('ContentFund', 'getFund', [activePost.savva_cid])
		const getTotalContributed = await contractRead('ContentFund', 'getTotalContributed', [activePost.savva_cid])
		const rewardPercent: number = await contractRead('Config', 'getUInt', [stringToBytes32('winnerShare')])
		const minFundToShare = await contractRead('Config', 'getUInt', [stringToBytes32('minFundToShare')])

		setconfig({
			minFundToShare,
			rewardPercent
		})

		const newFund = {
			...activePost.fund,
			total_contributed: getTotalContributed,
			contributions: fund.contributions,
			round_time: Number(fund.round_time)
		}

		setActivePost({
			...activePost,
			fund: { ...newFund }
		})
		setIsLoading(false)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [activePost?.savva_cid])

	useEffect(() => {
		if (!activePost?.savva_cid) return
		getFundData()
		// TODO AMOUNT REFRESH
	}, [activePost?.savva_cid, getFundData])

	if (!activePost?.savva_cid) return <></>

	return (
		<div className='flex w-full flex-col'>
			<Card
				className={
					'relative h-auto w-full overflow-hidden bg-gradient-to-bl from-sky-600 via-sky-800 to-sky-900 p-4 text-white shadow-lg'
				}
			>
				<CardTitle className='mt-2 flex items-center justify-center text-[15px]'>{langPack('fund.title')}</CardTitle>
				<CardContent className='flex flex-col items-center justify-center'>
					<AmountBlock amount={currentFund} size='lg' perUsd />
					{isLoading ? (
						<div className='flex h-[90px] items-center justify-center'>
							<Preloader small />
						</div>
					) : (
						<>
							{activePost.fund.round_time && activePost.fund.round_time !== '18446744073709551615' ? (
								<>
									<div className='mt-4 flex flex-col items-center justify-center'>
										<div>{finish ? langPack('fund.wait_round') : langPack('fund.next_round')}</div>
										<AmountBlock amount={nextReward} size='md' />
									</div>
									<div className='z-40 mt-4 w-full'>
										<CountdownTimer
											targetDate={Number(activePost?.fund.round_time) * 1000}
											onFinish={state => setFinish(state)}
										/>
									</div>
								</>
							) : null}
							<div className='mt-4 flex flex-col items-center justify-center gap-2'>
								<div className='text-[12px]'>{langPack('fund.total_invested')}</div>
								<div>
									<AmountBlock amount={Number(activePost?.fund.total_contributed)} size='sm' />
								</div>
							</div>
						</>
					)}

					{!finish && (
						<div className='right-[85px] z-30 mt-6 min-w-28'>
							<ContributeModal />
						</div>
					)}
					<div className='absolute bottom-[40px] right-[85px] z-10 scale-[13] opacity-[0.1] grayscale'>
						<SavvaIcon />
					</div>
				</CardContent>
			</Card>
			<Card className='mx-auto -mt-[5px] box-border flex max-w-[95%] flex-col rounded-t-none p-3 shadow-lg'>
				<div className='mt-3 text-[14px] font-bold'>{langPack('fund.note_title')}</div>
				<p className='mt-2 text-[12px]'>{langPack('fund.note_one')}</p>
				<p className='mt-2 text-[12px]'>{langPack('fund.note_two')}</p>
			</Card>
		</div>
	)
}
