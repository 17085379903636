import useRequest from '@/shared/lib/use-request'
import { useEffect, useState } from 'react'
import { AppDetailStore, ConfigStore, LangStore } from '@/shared/model/store'
import { Grid, List } from 'lucide-react'
import FeedBlockLine from '@/entities/feed-block/ui/feed-block-line'
import FeedBlockGrid from '@/entities/feed-block/ui/feed-block-grid'
import { cn } from '@/shared/lib'
import { FeedPageType } from '@/pages/feed-page'
import { ToggleGroup, ToggleGroupItem } from '@/shared/ui/toggle-group'
import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue } from '@/shared/ui/select'
import { UserStore } from '@/entities/user'
import { NoContent } from '@/entities/feed-block/ui/no-content'
import { ContentStore } from '@/entities/feed-block/model/content-store'
import { ContentType } from '@/entities/feed-block/model/types'
import BlogUnit from '@/shared/ui/skeletons/ui/blog-unit'
import { useSubscribe } from '@/shared/lib/use-subscribe'
import { IContent } from '@/widgets/post/model/types'

type FeedProps = FeedPageType & {
	author_addr?: `0x${string}`
}

type TimeFilter = { id: number; name: string; type: string; min_time: number; max_time: number }

const LIMIT = 200

const getParams = (pageName: FeedPageType['pageType'], user?: `0x${string}`) => {
	switch (pageName) {
		case 'leaders':
			return { order_by: 'total_author_share' }
		case 'actual':
			return { order_by: 'fund_amount' }
		case 'for-you':
			return user ? { user_addr: user } : {}
		default:
			return {}
	}
}

const calculateDates = (v: TimeFilter) => {
	if (!v.id) return {}
	const now = Date.now()
	const calculated = now - v!.max_time * 1000

	return {
		min_time: new Date(calculated).toISOString().slice(0, 10),
		// max_time: new Date().toISOString().slice(0, 10)
	}
}

type FilterTypes = {
	domain: string
	content_type: 'post'
	limit: number
	author_addr?: `0x${string}`
	user_addr?: `0x${string}`
	offset?: number
	order_by?: string
	min_time?: string
	max_time?: string
	category?: string
	show_nsfw?: boolean
}

export const Feed = ({ pageType, author_addr }: FeedProps) => {
	const { appDetails } = AppDetailStore()
	const { langPack, lang } = LangStore()
	const { userData } = UserStore()
	const { setContentList, contenList } = ContentStore()
	const { domain } = ConfigStore()
	const { fetch, isLoading, isSuccess } = useRequest<{ list: ContentType[] }>('getContentList')
	const { message: auction_created} = useSubscribe('nft_auction_created')
	const { message: auction_canceled } = useSubscribe('nft_auction_canceled')
	const { message: auction_ended } = useSubscribe('nft_auction_ended')
	const { message: auction_bid } = useSubscribe('nft_auction_bid')
	
	const [isGrid, setIsGrid] = useState<string>(localStorage.getItem('lt') || 'list')

	const DEFAULT_PARAMS: FilterTypes = {
		domain: domain,
		content_type: 'post',
		limit: LIMIT,
		show_nsfw: false,
		...(author_addr && {
			author_addr
		}),
		...(userData?.address && {
			my_addr: userData.address,
			show_nsfw: ['w', 's'].includes(userData.nsfw)
		})
	}

	const [fetchParams, setFetchParams] = useState<FilterTypes>({} as FilterTypes)
	const [selectedCat, setSelectedCat] = useState(langPack('feed.all_rubrics'))

	const categories_from_modules = appDetails?.modules.categories.locales[lang] || []
	const categories = [...[langPack('feed.all_rubrics')], ...categories_from_modules]

	const timeFilterData: TimeFilter[] = [
		{ id: 1, name: langPack('feed.week'), type: '7d', min_time: 0, max_time: 604800 },
		{ id: 2, name: langPack('feed.month'), type: '1m', min_time: 0, max_time: 2419200 },
		{ id: 3, name: langPack('feed.year'), type: '1y', min_time: 0, max_time: 31536000 },
		{ id: 4, name: langPack('feed.all_time'), type: 'all', min_time: 0, max_time: 0 } // REMOVE MA TIME
	]

	const getListByPeriod = (value: string) => {
		const v = timeFilterData.find(el => el.type === value) || ({} as TimeFilter)
		let current = { ...fetchParams }

		if (v!.max_time === 0) {
			delete current.min_time
			delete current.max_time
		} else {
			current = {
				...fetchParams,
				offset: 0,
				...getParams(pageType),
				...calculateDates(v)
			}
		}

		setFetchParams(current)
	}

	const activeRubricHandler = rubric => {
		setSelectedCat(rubric)
		if (rubric !== langPack('feed.all_rubrics')) {
			setFetchParams({
				...fetchParams,
				category: `${lang}:${rubric}`
			})
		} else {
			const p = { ...fetchParams }
			delete p.category
			setFetchParams(p)
		}
	}

	useEffect(() => {
		setSelectedCat(langPack('feed.all_rubrics'))
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: 'smooth'
		})
		setFetchParams({
			...DEFAULT_PARAMS,
			...(pageType === 'leaders' && { ...calculateDates(timeFilterData[1]), ...getParams(pageType) }),
			...getParams(pageType, userData?.address)
		})
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pageType])

	useEffect(() => {
		if (!Object.keys(fetchParams).length) return
		fetch({
			params: fetchParams,
			onSuccess(res) {
				setContentList(res.list)
			}
		})
	}, [fetch, fetchParams, setContentList])

	const onValueChange = (value: string) => {
		if (!value) return
		setIsGrid(value)
		localStorage.setItem('lt', value)
	}

	useEffect(() => {
		if(!auction_created) return
		const updatedList = contenList.map((el: ContentType) => el.savva_cid === auction_created.content_id
			? {...el, nft: {...el.nft, on_auction: true, min_bid: auction_created.min_price, auction_end_time: auction_created.end_time}}
			: el)
		setContentList(updatedList)
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [auction_created])

	useEffect(() => {
		if(!auction_canceled) return
		const updatedList = contenList.map((el: ContentType) => el.savva_cid === auction_canceled.content_id
			? {...el, nft: {...el.nft, on_auction: false}}
			: el)
		setContentList(updatedList)
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [auction_canceled])

	useEffect(() => {
		if(!auction_ended) return
		const updatedList = contenList.map((el: ContentType) => el.savva_cid === auction_ended.content_id
			? {...el, nft: {...el.nft, on_auction: false}}
			: el)
		setContentList(updatedList)
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [auction_ended])
	
	useEffect(() => {
		if(!auction_bid) return
		const updatedList = contenList.map((el: ContentType) => el.savva_cid === auction_bid.content_id
			? {...el, nft: {...el.nft, highest_bid: auction_bid.price}}
			: el)
		setContentList(updatedList)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [auction_bid])

	useEffect( () => () => setContentList([]), [] );

	// const tabName = appDetails.modules.tabs.tabs.find(el => el.type === pageType)?.title[lang]

	return (
		<div>
			<div className='row flex gap-2'>
				{/* <Helmet>
					<title>{`${domain.toLocaleUpperCase()} :: ${tabName}`}</title>
				</Helmet> */}
				<ToggleGroup
					defaultValue='list'
					type='single'
					className='hidden lg:flex lg:flex-row'
					onValueChange={onValueChange}
					value={isGrid}
				>
					<ToggleGroupItem value='list' key={'list'}>
						<List />
					</ToggleGroupItem>
					<ToggleGroupItem value='grid' key={'grid'}>
						<Grid />
					</ToggleGroupItem>
				</ToggleGroup>
				<Select onValueChange={activeRubricHandler} value={selectedCat}>
					<SelectTrigger>
						<SelectValue />
					</SelectTrigger>
					<SelectContent>
						<SelectGroup>
							{categories.map((item: string) => (
								<SelectItem key={item} value={item}>
									{item}
								</SelectItem>
							))}
						</SelectGroup>
					</SelectContent>
				</Select>
				{pageType === 'leaders' && (
					<Select onValueChange={getListByPeriod} defaultValue={timeFilterData[1].type}>
						<SelectTrigger>
							<SelectValue />
						</SelectTrigger>
						<SelectContent>
							<SelectGroup>
								{timeFilterData.map(item => (
									<SelectItem key={item.id} value={item.type}>
										{item.name}
									</SelectItem>
								))}
							</SelectGroup>
						</SelectContent>
					</Select>
				)}
			</div>

			{isLoading ? 
				<div className={cn(isGrid === 'grid' ? 'grid grid-cols-3 gap-4' : 'flex flex-col gap-4 relative')}>
					{[...Array(12)].map((l: number) => (<BlogUnit key={l} isGrid={isGrid === 'grid'}/> ))}
				</div>
				: null
			}

			{isSuccess && contenList.length < 1 ? (
				<NoContent isLoading={isLoading} />
			) : (
				<div className={cn(isGrid === 'grid' && 'grid grid-cols-3 gap-4 ')}>
					{contenList.map((el: ContentType) =>
						isGrid === 'grid' ? (
							<FeedBlockGrid content={el} key={el.savva_cid} />
						) : (
							<FeedBlockLine content={el} key={el.savva_cid} />
						)
					)}
				</div>
			)}
		</div>
	)
}
