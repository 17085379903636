import { useEffect, useState } from 'react'
import { ContentType } from '../model/types'
import { cn, DEFAULT_PREC, parceEthAmount } from '@/shared/lib'
import { AmountBlock } from '@/shared/ui/amount-block/amount-block'
import { LangStore } from '@/shared/model/store'
import PerUsd from '@/features/per-usd/ui/per-usd'
import { CountdownTimer } from '@/shared/ui/countdown-timer'

type Props = {
	content: ContentType
	className?: string
}

export const FundRibbon = ({ content, className }: Props) => {
	const [finish, setFinish] = useState<boolean>(Number(content?.fund.round_time) * 1000 < Date.now() || false)

	const [fund, setFund] = useState<number>(0)
	const [blink, setBlink] = useState<boolean>(false)
	const { langPack } = LangStore()

	useEffect(() => {
		setFund(content.fund.amount)
	}, [content.fund.amount])

	useEffect(() => {
		setBlink(true)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [fund])

	useEffect(() => {
		blink && setTimeout(() => setBlink(false), 1500)
	}, [blink])

	return (
		<>
			{content.fund.amount > 0 && (
				<div className={cn(blink && 'animate-bounce', 'ribbon', className)}>
					<AmountBlock
						amount={content.fund.amount} size='md'
						description={
							<div className='flex flex-col items-center'>
								<div>{langPack('fund.title')}</div>
								<div className='font-bold text-[16px] mt-2'>{parceEthAmount(content.fund.amount)} SAVVA</div>
								<PerUsd amount={content.fund.amount / DEFAULT_PREC} bracket token='SAVVA'/>
								<div className='mt-2'>{finish ? langPack('fund.wait_round') : langPack('fund.next_round')}</div>
								<div className='z-40 mt-4 w-full'>
										<CountdownTimer
											targetDate={Number(content?.fund.round_time) * 1000}
											onFinish={state => setFinish(state)}
										/>
								</div>
							</div>
						}
						/>
				</div>
			)}
		</>
	)
}
