"use client"

import React, { useEffect, useState } from "react"
import { FileWithPath, useDropzone } from "react-dropzone"
import { ImageCropper } from "../image-cropper"
import { Avatar, AvatarFallback, AvatarImage } from "./avatar"
import { MousePointerClick, RefreshCw, Trash, User } from "lucide-react"
import { cn, stringToBytes32, updloadToIpfs, useTheme } from "@/shared/lib"
import { ContractsStore } from "@/shared/model/store/contracts-store"
import useNotifier from "@/shared/lib/use-notifier"
import { IUser } from "@/entities/user/model/types"
import { ConfigStore } from "@/shared/model/store"
import { BADPIC, IPFSProfileType } from "@/pages/profile/ui/profile"
import { Button } from "@/shared/ui"

import defaultAvatar from '@/shared/assets/images/avatar-default.svg'
import banner from '@/shared/assets/images/banner.png'

export type FileWithPreview = FileWithPath & {
  preview: string
}

const accept = {
  "image/*": [],
}

function dataURLtoFile(dataurl, filename) {
  const arr = dataurl.split(',')
  const mime = arr[0].match(/:(.*?);/)[1]
  const bstr = atob(arr[arr.length - 1])
  let n = bstr.length
  const u8arr = new Uint8Array(n)
  while(n--){
      u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, {type:mime});
}

interface ILoadAvatar {
  profile: IUser & IPFSProfileType;
  type: 'avatar' | 'banner';
  isMy: boolean; 
  update: () => void
}

export default function PageLoadAvatar({ profile, type, isMy, update }: ILoadAvatar ) {
  const { contracts } = ContractsStore()
  const { theme  } = useTheme()
  const { avatar } = profile
  const { domain, baseUrl } = ConfigStore()
  const [show, setShow] = useState<boolean>(false)
  const [selectedFile, setSelectedFile] =
    React.useState<FileWithPreview | null>(null)
  const [isDialogOpen, setDialogOpen] = React.useState(false)

  const onDrop = React.useCallback(
    (acceptedFiles: FileWithPath[]) => {
      const file = acceptedFiles[0]
      if (!file) {
        alert("Selected image is too large!")
        return
      }

      const fileWithPreview = Object.assign(file, {
        preview: URL.createObjectURL(file),
      })

      setSelectedFile(fileWithPreview)
      setDialogOpen(true)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  const { writeContract: writeSetAvatar, isLoading, isError } = useNotifier({
    onSuccess() {
      update()
      setSelectedFile(null)
    }
  })

	const { writeContract: writeSetAll, isLoading: isLoadingBanner } = useNotifier({
    onSuccess() {
      update()
      setSelectedFile(null)
    },
  })

  const getPic = (link: string, type?: string) => {
		const newLink = link
		if (link && link.length > 0) {
			if (link === BADPIC) return defaultAvatar
			return `${baseUrl}ipfs/${newLink}`
		}
		return type === 'banner' ? banner : defaultAvatar
	}

  const onSave = async (file: string, remove = false) => {
    const fileObject = remove ? null : dataURLtoFile(file, 'avatar')
    const bannerIPFS = fileObject ? await updloadToIpfs('', fileObject) : null
		const fileType = fileObject ? fileObject.type.split('/')[1] : ''

		const fullLink = remove ? '' : `${bannerIPFS}?filename=.${fileType}`
    switch (type) {
			case 'avatar':
				writeSetAvatar({
					address: contracts.UserProfile.address,
					abi: contracts.UserProfile.abi,
					functionName: 'setAvatar',
					args: [fullLink]
				})
				break
			case 'banner':
        // eslint-disable-next-line no-case-declarations
        const newBody = {
          about: profile.about,
          display_name: profile.display_name,
          location: profile.location,
          site: profile.site,
          sponsor_values: profile.sponsor_values,
          nsfw: profile.nsfw,
          name: profile.name,
          sendMessage: profile.sendMessage,
          banner: fullLink
        }
				// eslint-disable-next-line no-case-declarations
				const res = remove ? '' : await updloadToIpfs(
					JSON.stringify(newBody)
				)

				writeSetAll({
					address: contracts.UserProfile.address,
					abi: contracts.UserProfile.abi,
					functionName: 'setAll',
					args: [profile.name, profile.avatar, stringToBytes32(domain), res]
				})
				break
			default:
				break
		}
  }

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept,
  })

  useEffect(() => {
    if(isError) {
      setSelectedFile(null)
    }
  }, [isError])

  const loader = type === 'avatar' && isLoading || type === 'banner' && isLoadingBanner
  
  const isDark = theme === 'dark'

  return (
    <div className="relative ">
        <ImageCropper
          dialogOpen={isDialogOpen}
          setDialogOpen={isMy ? setDialogOpen : () => {}}
          selectedFile={selectedFile}
          setSelectedFile={setSelectedFile}
          onSave={onSave}
          aspect={type === 'avatar' ?  1 : 6 / 1}
          isLoading={isLoading}
        >
            {loader && <div className={cn("absolute flex flex-row items-center justify-center w-full h-full z-5 backdrop-blur-lg", type === 'avatar' && 'rounded-[50%] scale-80')}>
              <RefreshCw size={40}  color="#FFF" className="animate-spin" />
            </div>}
          {
            type === 'avatar' ? (
              <Avatar
                {...(isMy ? {...getRootProps()} : {})}
                onMouseEnter={() => isMy && setShow(true)}
                onMouseLeave={() => isMy && setShow(false)}
                className="size-52 cursor-pointer ring-offset-2 ring-2 ring-slate-200 shadow-lg bg-secondary z-22"
              >
                <input {...getInputProps()} />
                <AvatarImage src={getPic(profile.avatar, 'avatar')} alt="@shadcn" />
                <AvatarFallback><User /></AvatarFallback>
                {show &&
                    <div className="absolute flex flex-row items-center justify-center backdrop-blur-lg w-full h-full rounded-[50%]">
                      <Button className="flex flex-row gap-2">
                        <MousePointerClick size={16}/>
                        <div>Click to update</div>
                      </Button>
                   </div>
                }
            </Avatar>
            ) : (
              <div 
                className="flex flex-col w-full object-fill relative" 
                {...(isMy ? {...getRootProps()} : {})}
                onMouseEnter={() => isMy && setShow(true)}
                onMouseLeave={() => isMy && setShow(false)}
                >
                  <input {...getInputProps()}/>
                  <img src={getPic(profile.banner, 'banner')} />
                  {show &&
                      <div className="absolute flex flex-row items-center justify-center backdrop-blur-lg w-full h-full text-[]">
                          <Button className="gap-4"><MousePointerClick />Click to update banner</Button>
                      </div>
                  }
                 {isDark && !profile.banner &&  <div className="absolute w-full">
                    <div className='relative h-[152px] overflow-hidden w-full flex'>
                      <div className="absolute inset-0  bg-gradient-to-b from-black via-black to-transparent opacity-80 rounded-lg z-2 h-[280px] w-[calc(100%+2rem)] rotate-[10deg] -top-[90px] left-[0px]" />
                      <div className="absolute inset-0  bg-gradient-to-t from-black via-black to-transparent opacity-80 rounded-lg z-2 h-[120px] w-[250px] rotate-[30deg] top-[80px] -left-[40px]" />
                    </div>
                  </div>}
                 
              </div>
            )
          }
        
        </ImageCropper>
        <div className='absolute left-[150px] -bottom-[10px] z-22'>
          {isMy && type === 'avatar' && avatar && avatar.length && <Button size='sm' variant={'default'} onClick={() => onSave('', true)} disabled={isLoading}>
              <Trash size={16} />
            </Button>}
        </div>
        <div className='absolute right-[25px] top-[10px] z-7'>
          {isMy && type === 'banner' && profile.banner && profile.banner.length && <Button size='sm' variant={'default'} onClick={() => onSave('', true)} disabled={isLoadingBanner}>
              <Trash size={16} />
            </Button>}
        </div>
    </div>
  )
}