import { LayoutSidebar } from '@/shared/layout'
import { checkEthAddress, DEFAULT_PREC, getEllipsisTxt, toast } from '@/shared/lib'
import useRequest from '@/shared/lib/use-request'
import { ConfigStore, LangStore } from '@/shared/model/store'
import { Button, Card, Popover, PopoverContent, PopoverTrigger, Preloader } from '@/shared/ui'
import { getContentFromHash } from '@/widgets/post-editor/lib/utils'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import {  Copy, Ellipsis, ShieldX } from 'lucide-react'
import MarkDownPreview from '@uiw/react-markdown-preview'
export const BADPIC = 'QmbFMke1KXqnYyBBWxB74N4c5SBnJMVAiMNRcGu6x1AwQH'
import './style.css'
import { UserName } from '@/shared/ui/user-name'
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/shared/ui/tabs'
import SidebarProfile from './sidebar-profile'
import Blog from './blog'
import Followings from './follow/following'
import { UserStore } from '@/entities/user'
import { AmountBlock } from '@/shared/ui/amount-block/amount-block'
import Settings from './settings/settings'
import WalletMain from './wallet'
import { IUser } from '@/entities/user/model/types'
import Sponsors from './sponsors'
import SponsorModal from './sponsors/sponsor-modal'
import FollowButton from './follow/follow-button'
import { Chat } from '@/widgets/chat/ui'
import { CommentFeed } from '@/widgets/feed/ui/comment-feed'
import { AuthorContentBlocker } from '@/widgets/post/ui/author-content-blocker'
import { useSubscribe } from '@/shared/lib/use-subscribe'
import PageLoadAvatar from '@/widgets/image-cropper/ui/avatar-component'
import { NoUser } from './no-user'
import PerUsd from '@/features/per-usd/ui/per-usd'

export type IPFSProfileType = {
	about: string
	display_name: string
	location: string
	site: string
	sponsor_values: number[]
	nsfw: string
	name: string
	sendMessage: string
	banner: string
	chat_welcome: string
}

export type ProfileProps = {
	profile: IUser & IPFSProfileType
	getUserFetch?: () => void
	isSubscribed?: boolean
	if_follow_me?: boolean
	is_my?: boolean
	followers?: boolean
	other?: boolean
	otherDomain?: string
	edit?: boolean
}

export interface IContentFilter {
	category?: string
	tag?: string
}

export const ProfilePage = () => {
	const { id } = useParams()
	const uid = id?.replace('@', '') || ''
	const queryParameters = new URLSearchParams(window.location.search)
	const current: string = queryParameters.get('p') as string

	const navigate = useNavigate()

	const {message: bun} = useSubscribe('banned_user')
	const {message: unbun} = useSubscribe('unbanned_user')

	const { domain } = ConfigStore()
	const { userData, setUserData, isAdmin } = UserStore()
	const { langPack } = LangStore()
	const [more, setMore] = useState<boolean>(false)
	const { fetch, isLoading, isError, setError } = useRequest('getUser')
	const [profileData, setProfileData] = useState<IUser & IPFSProfileType>({} as IUser & IPFSProfileType)
	const [currentTab, setCurrentTab] = useState<string>(current ?? 'blog')
	const [filters, setFilters] = useState<IContentFilter>({} as IContentFilter)

	const isMy = userData?.address === profileData.address

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const getUserFetch = () => {
		fetch({
			params: {
				...(checkEthAddress(uid) && { user_addr: uid }),
				...(!checkEthAddress(uid) && { user_name: uid }),
				domain: domain,
				...(userData?.address && { caller: userData.address })
			},
			onSuccess: async res => {
				if(!res) {
					setError({isError: true, errorMessage: 'Not found'})
					return
				}

				const userProfile = await getContentFromHash(res.profile_cid)
				const parced = userProfile ? JSON.parse(userProfile) : {}
				if (parced.name && checkEthAddress(uid)) {
					navigate(`/@${parced.name}`, { state: { from: `/${id}` } });
				}

				setProfileData({
					...parced,
					...res
				})

				if(!!userData?.address && userData?.address === res.address) {
					setUserData({
						...parced,
						...res
					})
				}
			}
		})
	}

	useEffect(() => {
		setCurrentTab('blog')
	}, [filters])
	
	useEffect(() => {
		setProfileData({} as IUser & IPFSProfileType)
		if (!uid) return
		setCurrentTab('blog')
		getUserFetch()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [uid])

	const copy = () => {
		navigator.clipboard.writeText(profileData.address)
		toast({
			duration: 5000,
			key: 'copy_promo',
			title: langPack('profile.copied_text')
		})
	}

	useEffect(() => {
		if((bun?.user?.address || unbun?.user?.address) === profileData.address) {
			getUserFetch()
		}
	}, [bun, unbun])

	useEffect(() => {
		if(userData?.address && currentTab === 'settings') {
			setCurrentTab('blog')
		}
	}, [userData?.address])

	if(isLoading) {
		<div className='mt-3 flex size-full flex-col items-center justify-center rounded-lg border-[1px] border-dashed'>
			<Preloader small />
		</div>
	}

	if(isError) {
		return (
			<NoUser isLoading={isLoading}/>
		)
	}

	return (
		<LayoutSidebar
			sidebar={<SidebarProfile profile={profileData} setFilters={setFilters} filters={filters} />}
			loading={!profileData.address && isLoading}
		>
			<div className='overflow-hidden rounded-lg pb-6 shadow-md  bg-card' >
				{/* <div className={`bg-[url(${ image.length ? image : 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRmCy16nhIbV3pI1qLYHMJKwbH2458oiC9EmA&s'})] h-[250px] relative`}> */}
				<div
					className='relative h-[220px]'
				>
					<PageLoadAvatar profile={profileData} type='banner' isMy={isMy} update={getUserFetch}/>
					<div className='absolute bottom-0 grid w-full grid-cols-3 grid-rows-1'>
						<div className='relative flex h-[60px] flex-row bg-card'>
							<div className='absolute bottom-[0px] left-[20%] z-22'>
								<PageLoadAvatar profile={profileData} type='avatar' isMy={isMy} update={getUserFetch}/>
							</div>
						</div>
						
						<div className='col-span-2 flex h-[60px] flex-row items-center justify-end bg-card pr-[20px]'>
							{userData?.address !== profileData.address && profileData.address && (
								<div className='flex flex-row gap-4 items-center'>
									<SponsorModal profile={profileData} getUserFetch={getUserFetch} edit={profileData.i_sponsor_for > 0} other={profileData.i_sponsor_for > 0}/>
									<FollowButton
										profile={profileData}
										getUserFetch={getUserFetch}
										isSubscribed={profileData.is_followed}
									/>
									{profileData.is_followed && <Chat hiddenBage userAddress={profileData.address}/>}
									{isAdmin && <Popover>
										<PopoverTrigger asChild>
											<Button size='icon' variant='secondary' className='px-2'>
												<Ellipsis />
											</Button>
										</PopoverTrigger>
										<PopoverContent className='p-0'>
											<AuthorContentBlocker author={profileData} domain={domain} type='author' onAction={getUserFetch} />
										</PopoverContent>
									</Popover>
									}
								</div>
							)}
						</div>
					</div>
				</div>
				<div className='m-auto mt-4 w-[90%]'>
					<div className='flex flex-row items-center gap-4'>
						<UserName account={profileData} className='text-[16px] font-bold' sliceLen={100} />
						<div className='flex flex-row items-center gap-2'>
							{(!!profileData.display_name || !!profileData.name) && (
								<div className='text-grey text-[12px]'>{getEllipsisTxt(profileData.address)}</div>
							)}
							<Copy className='cursor-pointer' size={13} onClick={copy} />
						</div>
					</div>
					<div className='flex flex-row gap-8'>
						<div className='flex flex-row items-center gap-2'>
							<div className='text-[grey]'>{langPack('profile.sponsor_other')}</div>
							<AmountBlock amount={profileData.total_sponsoring} size='sm'/>
							{profileData.total_sponsoring ? <PerUsd amount={profileData.total_sponsoring / DEFAULT_PREC} token='SAVVA' bracket className='text-[gray] text-[12px]'/> : null}
						</div>
						<div className='flex flex-row items-center gap-2'>
							<div className='text-[grey]'>{langPack('profile.sponsors_users')}</div>
							<AmountBlock amount={profileData.total_sponsored} size='sm' />
							{profileData.total_sponsored ? <PerUsd amount={profileData.total_sponsored / DEFAULT_PREC} token='SAVVA' bracket className='text-[gray] text-[12px]'/> : null}
						</div>
					</div>
				</div>
				<div className='m-auto mt-4 flex w-[90%] flex-col'>
					<MarkDownPreview
						source={
							profileData.about?.length > 180 && !more ? `${profileData.about.slice(0, 180)}...` : profileData.about
						}
						className='bg-background'
						style={{ whiteSpace: 'pre-wrap', background: 'transparent', color: 'grey', fontSize: 14 }}
					/>
					{profileData.about?.length > 180 && (
						<div className='flex w-full cursor-pointer flex-row text-[14px] text-[gray]' onClick={() => setMore(!more)}>
							{more ? '...less' : 'more...'}
						</div>
					)}
				</div>
			</div>
			{profileData.banned && 
				<Card className='p-8 flex flex-col my-3'>
					<div className='font-bold flex flex-row gap-2 text-[red]'>
						<ShieldX size={22}/>
						<div>This author is blocked:</div>
					</div>
					<div>{profileData.ban_comment}</div>
				</Card>
			}
			<div className='m-auto mt-5 w-full'>
				<Tabs defaultValue='blog' className='w-full' value={currentTab} onValueChange={setCurrentTab}>
					<div className='flex flex-row gap-3'>
						<TabsList className='grid w-full grid-cols-4'>
							<TabsTrigger value='blog'>{langPack('profile.blog')}</TabsTrigger>
							<TabsTrigger value='following' className='gap-1'>{`${langPack('profile.followings')}`}<span className='font-bold'>{profileData.n_following ?? 0}</span></TabsTrigger>
							<TabsTrigger value='followers' className='gap-1'>{`${langPack('profile.followers')}`}<span className='font-bold'>{profileData.n_followers ?? 0}</span></TabsTrigger>
							{/* <TabsTrigger value='comments'>{langPack('profile.comments')}</TabsTrigger> */}
							<TabsTrigger value='sponsees'>{langPack('profile.sponsoring')}</TabsTrigger>
						</TabsList>
						<TabsList className=''>
							<TabsTrigger value='wallet' className='flex flex-row items-center gap-2'>
								{langPack('profile.wallet')}
							</TabsTrigger>
							{profileData?.address === userData?.address && (
								<TabsTrigger value='settings' className='flex flex-row items-center gap-2'>
									{langPack('profile.settings')}
								</TabsTrigger>
							)}
						</TabsList>
					</div>
					<TabsContent value='blog' className='p-2'>
						<Blog profile={profileData} filters={filters} />
					</TabsContent>
					<TabsContent value='wallet'>
						<WalletMain profile={profileData} />
					</TabsContent>
					<TabsContent value='following'>
						<Followings profile={profileData} getUserFetch={getUserFetch} />
					</TabsContent>
					<TabsContent value='comments'>
						<div className='mt-6 flex w-full'>
							<CommentFeed author={profileData.address} />
						</div>
					</TabsContent>
					<TabsContent value='followers'>
						<Followings profile={profileData} followers getUserFetch={getUserFetch} />
					</TabsContent>
					<TabsContent value='settings'>
						<Settings profile={profileData} getUserFetch={getUserFetch} />
					</TabsContent>
					<TabsContent value='sponsees'>
						<Sponsors profile={profileData} getUserFetch={getUserFetch} />
					</TabsContent>
				</Tabs>
			</div>
			<div className='h-[120px]' />
		</LayoutSidebar>
	)
}
