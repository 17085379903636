import { ContentLangToggle } from '@/features/content-lang-toggle'
import { ConfigStore, LangStore } from '@/shared/model/store'
import { Card, Preloader } from '@/shared/ui'
import { useLocation, useNavigate } from 'react-router-dom'
import { getContentFromYaml } from '../api/get-content-from-yaml'
import { ContentType } from '@/entities/feed-block/model/types'
import { ContentForm } from './form'
import { FormProvider, useForm } from 'react-hook-form'
import { formSchema, getDefaultValues } from '../model/model'
import { zodResolver } from '@hookform/resolvers/zod'
import { z } from 'zod'
import { Blocker } from './blocker'
import { useContentLang } from '../lib/hook/use-content-lang'
import { useEffect, useState } from 'react'
import { createCid, getFormData } from '../lib/utils'
import useRequest from '@/shared/lib/use-request'
import { useAccount } from 'wagmi'
import { createPostBody } from '../lib/create-post-body'
import useNotifier from '@/shared/lib/use-notifier'
import { ContractsStore } from '@/shared/model/store/contracts-store'
import { PostCover } from './cover'
import { FileDrawer } from './drawer'
import { useCheckStaking } from '../lib/hook/use-check-staking'
import StakeModal from '@/pages/profile/ui/wallet/modals/stake-modal'
import { UserStore } from '@/entities/user'
import { SponsorsSumm } from './sponsors-summ'
import { stringToBytes32 } from '@/shared/lib'

type Props = {
	contentType: 'post' | 'comment' | 'post-edit' | 'comment-edit'
}

//FIXME: Удалять фолдер везде кроме нового поста фолдер контент
//FIXME: чекбокс зарег как новый оставить только для редактирования

export const ContentEditor: React.FC<Props> = ({ contentType }) => {
	const { lang, langPack } = LangStore()
	const { domain, baseUrl } = ConfigStore()
	const { state } = useLocation() as { state: ContentType | null }
	const { contentLang, setContentLang, contentLocales } = useContentLang({ lang, state })
	const { signatureAddress } = UserStore()
	const navigate = useNavigate()
	const { address } = useAccount()
	const { isLowStake, refetch, minStake } = useCheckStaking({ address: signatureAddress })
	const [saveForm, setSaveForm] = useState(null)

	const form = useForm<z.infer<typeof formSchema>>({
		resolver: zodResolver(formSchema),
		defaultValues: async () => getDefaultValues(contentLocales, contentType )
	})

	const pathToFolder = contentType === 'post' ? 'new-post' : 'content'

	const { fetch: copyFolderFetch, isLoading: isLoadingFolder } = useRequest('copyFolderCid')
	const { fetch: fetchFiles, isLoading: isLoadingUserFiles } = useRequest('getUserFiles')
	const { fetch: fetchUploadFiles, isLoading: isLoadingUpload } = useRequest('uploadUserFiles')
	const { fetch: fetchIpfsUserDir, isLoading: isLoadingUserDir } = useRequest('ipfsUserDir')
	const { fetch: deleteFile } = useRequest('deleteFile')
	const { contracts } = ContractsStore()

	const {
		writeContract,
		isLoading: isLoadingWrite,
		isConfirmed,
	} = useNotifier({
		onSuccess: async () => {
			await deleteFile({ params: { path: pathToFolder } })
			contentType === 'post' ? navigate('/new') : navigate(-1)
		}
	})

	const isLoading = isLoadingWrite || isLoadingUserDir || isLoadingUpload

	const copyFolderHandler = async () => {
		await copyFolderFetch({
			params: {
				cid: state?.ipfs,
				path: pathToFolder
			},
			onSuccess: async () => {
				await Promise.all([getUserYaml(), getFiles()])
			}
		})
	}

	const getUserYaml = async () => {
		const res = await getContentFromYaml(`${baseUrl}user-file/${pathToFolder}/`)

		const { locales } = form.getValues()

		if (res) {
			const updateLocales = Object.keys(res.locales).reduce((acc, key) => {
				if (Object.prototype.hasOwnProperty.call(locales, key)) {
					acc[key] = res.locales[key]
				}
				return acc
			}, {})

			setSaveForm({
				...form.getValues(),
				locales: {
					...locales,
					...updateLocales
				}
			})
			Object.keys(updateLocales).forEach(lang => {
				form.setValue(`locales.${lang}`, updateLocales[lang])
			})
			form.setValue('isNsfw', res.nsfw)
			form.setValue('thumbnail', res.thumbnail)
			await getFiles()
		}
	}

	const getFiles = async () => {
		await fetchFiles({
			params: {
				path: `/${pathToFolder}/uploads/`
			},
			onSuccess: res => {
				form.setValue('files', res.files)
			}
		})
	}

	const onSubmit = async () => {
		if (!address) return
		const { isComment } = form.getValues()

		const rd = {
			parent_savva_cid: state?.savva_cid,
			root_savva_cid: state?.savva_content.root_savva_cid ? state?.savva_content.root_savva_cid : state?.savva_cid
		}

		const data = isComment ? rd : null
		const formData = createPostBody(data, address, domain, form, pathToFolder)

		await fetchUploadFiles({
			data: formData,
			isMultipart: true,
			onSuccess: async () =>
				await fetchIpfsUserDir({
					params: {
						path: pathToFolder
					},
					onSuccess: res => regContent(res.cid)
				})
		})
	}
	const regContent = async (cid: string) => {
		if (!address) return
		const { uid } = createCid(address, domain)

		writeContract({
			address: contracts.ContentRegistry.address,
			abi: contracts.ContentRegistry.abi,
			functionName: 'reg',
			args: [
				domain,
				//FIXME: state?.author.address удалить
				contentType === 'comment-edit' || contentType === 'post-edit' ? state?.author.address : address,
				contentType === 'comment-edit' || contentType === 'post-edit' || form.getValues().isRegisterNewPost
					? state?.guid
					: uid,
				cid,
				stringToBytes32(contentType)
			]
		})
	}

	const uploadUserFolder = async () => {
		const formData = getFormData(form, pathToFolder, domain)
		
		await fetchUploadFiles({
			data: formData,
			isMultipart: true
		})
	}

	useEffect(() => {
		if (contentType === 'comment') {
			deleteFile({ params: { path: pathToFolder } })
		}
		if (state && contentType !== 'comment') {
			copyFolderHandler()
		} else if (contentType === 'post') {
			getUserYaml()
		}
	}, [])

	if (form.formState.isLoading || isLoadingFolder || isLoadingUserFiles) return <Preloader />

	return (
		<Card className='p-4'>
			<FormProvider {...form}>
				<div className='flex items-start justify-between'>
					<div >
						<PostCover form={form} pathToFolder={pathToFolder}/>
						<FileDrawer form={form} pathToFolder={pathToFolder} contentLang={contentLang} />
					</div>
					{isLowStake && (
						<div className='mt-12'>
							<p className='mb-4 text-sm text-destructive'>{langPack('editor.increase_stake')} {minStake} SAVVA</p>
							<StakeModal getData={refetch} />
						</div>
					)}
					<SponsorsSumm />
					{contentLocales.length > 1 && (
						<ContentLangToggle locales={contentLocales} value={contentLang} setValue={setContentLang} />
					)}
				</div>

				<ContentForm
					contentLang={contentLang}
					setContentLang={setContentLang}
					form={form}
					onSubmit={onSubmit}
					pathToFolder={pathToFolder}
					isLoading={isLoading}
					contentType={contentType}
				/>
			</FormProvider>
			<Blocker state={saveForm} form={form} isConfirmed={isConfirmed} uploadUserFolder={uploadUserFolder}/>
		</Card>
	)
}
