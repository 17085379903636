import { create } from 'zustand'
import { persist } from 'zustand/middleware'
import { IUser } from './types'

interface IUserStore {
	userData: IUser | null
	setUserData: (state: IUser | null) => void
	signatureAddress: `0x${string}` | null
	setSignatureAddress: (state: `0x${string}` | null) => void
	isAdmin: boolean
	setIsAdmin: (state: boolean) => void
	deleteUser: () => void
}

const initialState = {} as IUser

export const UserStore = create<IUserStore>()(
	persist(
		set => ({
			userData: initialState,
			setUserData: state => set({ userData: state }),
			signatureAddress: null,
			setSignatureAddress: state => set({ signatureAddress: state }),
			isAdmin: false,
			setIsAdmin: state => set({ isAdmin: state }),
			deleteUser: () => set({ userData: initialState, signatureAddress: null })
		}),
		{
			name: 'user',
			partialize: state => ({
				signatureAddress: state.signatureAddress,
				...(state.userData && { userData: state.userData })
			})
		}
	)
)
