import { ContentStore } from '@/entities/feed-block/model/content-store'
import { contractRead } from '@/shared/lib'
import { useEffect } from 'react'
import { PositiveFundBox } from './positive-box'
import EmptyFundBox from './empty-box'
import useRequest from '@/shared/lib/use-request'

export const FundBox = () => {
	const { fetch } = useRequest('fix')
	const { activePost } = ContentStore()

	const readFundData = async () => {
		if (!activePost?.savva_cid) return
		try {
			const res = await contractRead('ContentFund', 'funds', [activePost.savva_cid])

			if ((Number(res[4]) !== activePost.fund.amount && activePost.fund.amount)
				|| (Number(res[5]) !== Number(activePost.fund.round_time) && activePost.fund.round_time)
			) {
				fetch({
					params: {
						fund: activePost.savva_cid
					}
				})
			}
		} catch (ERR) {
			console.log('GET FUND ERROR', ERR)
		}
	}

	useEffect(() => {
		readFundData()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [activePost?.savva_cid])

	if (!activePost?.savva_cid) return <></>

	return <>{activePost?.fund.amount > 0 ? <PositiveFundBox /> : <EmptyFundBox />}</>
}
