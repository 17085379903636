import { Button, Dialog, DialogContent, DialogFooter, DialogTrigger, Label } from '@/shared/ui'
import { useEffect, useState } from 'react'
import { ContentStore } from '@/entities/feed-block/model/content-store'
import defaultAvatar from '@/shared/assets/images/avatar-default.svg'
import { getName } from '@/shared/ui/user-name/ui/user-name'
import { getCurrentPostImage, getCurrentTitles } from '@/entities/feed-block/model/utils'
import { format } from './nft-block'
import { AppInfoStore, ConfigStore, LangStore } from '@/shared/model/store'
import { contractRead, parceEthAmount, stringToBytes32, updloadToIpfs } from '@/shared/lib'
import useNotifier from '@/shared/lib/use-notifier'
import { ContractsStore } from '@/shared/model/store/contracts-store'
import { UserStore } from '@/entities/user'

export type NFTObjectType = {
	name: string
	description: string
	external_url: string
	image: string
	attributes: { trait_type: string; value: string }[]
}

const NftMintDialog = () => {
	const [open, setOpen] = useState<boolean>(false)
	const [nftObject, setNftObject] = useState<NFTObjectType>({} as NFTObjectType)
	const [mintPrice, setMintPrice] = useState<bigint>()
	const { langPack, lang } = LangStore()
	const { activePost } = ContentStore()
	const { contracts, tokenPrice } = ContractsStore()
	const { appInfo } = AppInfoStore()
	const { userData } = UserStore()
	const { domain } = ConfigStore()

	const { writeContract, isLoading } = useNotifier({
		onSuccess() {
			setOpen(false)
		}
	})

	if (!activePost?.savva_cid || !userData?.address) return <></>

	const imageSrc = getCurrentPostImage(activePost, appInfo)


	{
		`(${format(activePost.timestamp, lang)}). ${getCurrentTitles(activePost.savva_content.locales, 'title')}`
	}

	const createNftObject = async () => {
		const nftImage = activePost.savva_content.thumbnail.length
		? activePost.savva_content.thumbnail
		: activePost.author.avatar.length
			? activePost.author.avatar
			: ''
		
		const object: NFTObjectType = {
			name: getCurrentTitles(activePost.savva_content.locales, 'title'),
			description: `${getName(activePost.author, 0)} (${format(activePost.timestamp, lang)}). ${getCurrentTitles(activePost.savva_content.locales, 'title')}`,
			external_url: `https://${domain}/content/${activePost.savva_cid}`,
			image: `ipfs://${nftImage}`,
			attributes: [
				{
					trait_type: 'Category',
					value: activePost.savva_content.locales[lang]?.categories?.join(',') || ''
				}
			]
		}

		setNftObject(object)
	}

	const mintNFT = async () => {
		const cid = await updloadToIpfs(JSON.stringify(nftObject))
		writeContract({
			address: contracts.ContentNFT.address,
			abi: contracts.ContentNFT.abi,
			functionName: 'mint',
			args: [userData.address, activePost.savva_cid, domain, activePost.guid, `ipfs://${cid}`],
			value: mintPrice
		})
	}

	const readMinPrice = async () => {
		const res: number | bigint = (await contractRead('Config', 'getUInt', [stringToBytes32('contentNFT_mintPrice')])) || 0
		setMintPrice(res)
	}

	// eslint-disable-next-line react-hooks/rules-of-hooks
	useEffect(() => {
		if (!open) return
		readMinPrice()
		createNftObject()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [open])

	return (
		<Dialog onOpenChange={setOpen} open={open}>
			<DialogTrigger asChild>
				<Button className='w-full'>{langPack('nft.create_nft')}</Button>
			</DialogTrigger>
			<DialogContent className='sm:max-w-[825px]'>
				<div className='grid grid-cols-3 grid-rows-1 gap-4'>
					<div>
						<img className='h-42 w-56 rounded-lg object-cover' src={imageSrc} alt='savva.img' />
					</div>
					<div className='col-span-2 flex min-h-[200px] flex-col justify-between'>
						<Label>{langPack('nft.post_name')}</Label>
						<div className='text-[grey]'>{nftObject.name}</div>
						<Label>{langPack('nft.description')}</Label>
						<div className='text-[grey]'>{nftObject.description}</div>
						<Label>{langPack('nft.mint_price')}</Label>
						<div className='text-[grey]'>{`${parceEthAmount(mintPrice)} PLS (${(tokenPrice.base_token_price * Number(parceEthAmount(mintPrice))).toFixed(2)}$)`}</div>
						<div className='text-[grey]'>{langPack('nft.mint_warning')}</div>
					</div>
				</div>
				<DialogFooter>
					<Button onClick={mintNFT} disabled={isLoading} loading={isLoading}>
						{langPack('nft.create_nft')}
					</Button>
				</DialogFooter>
			</DialogContent>
		</Dialog>
	)
}

export default NftMintDialog
