import { LangStore } from '@/shared/model/store'
import { Button, CardTitle, Dialog, DialogContent, DialogTrigger, Label } from '@/shared/ui'
import { useEffect, useState } from 'react'
import { useAuctionConfig } from './use-auction'
import { AmountBlock } from '@/shared/ui/amount-block/amount-block'
import { useWallet } from '@/entities/wallet'
import { cn, DEFAULT_PREC, getSplitTransaction } from '@/shared/lib'
import { UserCard } from '@/entities/user'
import { Check } from 'lucide-react'
import AmountInput from '@/shared/ui/amount-input/ui/amount-input'
import { ContractsStore } from '@/shared/model/store/contracts-store'
import useNotifier from '@/shared/lib/use-notifier'
import { ContentStore } from '@/entities/feed-block/model/content-store'


const SelectBlock = ({label, active, balance, onClick}) => (
    <div className={cn('flex flex-col w-[48%] items-center justify-center border-[2px] p-4 rounded-lg relative cursor-pointer hover:opacity-75', active && 'border-[green]')} onClick={onClick}>
        <Label>{label}</Label>
        <AmountBlock amount={balance * DEFAULT_PREC} size='md' />
        <div className={cn('absolute -translate-y-1/2 right-4 bottom-2 border-2 rounded-full border-[grey] p-1 opacity-65', active && 'border-[green] opacity-100')}>
            <Check className={cn('stroke-[grey] opacity-65', active && 'stroke-[green] opacity-100')}/>
        </div>
    </div>
)


const BidModal = () => {
    const { getConfig, nft_auction_min_increment, nft_auction_max_increment } = useAuctionConfig()
    const { stakedBalance, savvaBalance } = useWallet()
    const { contracts } = ContractsStore()
    const { activePost } = ContentStore()
    const nft = activePost?.nft

    const [payer, setPayer] = useState<'SAVVA_VOTES' |  'SAVVA'>('SAVVA')
    const [bid, setBid] = useState<number>()

	const [open, setOpen] = useState(false)
	const { langPack } = LangStore()

    const { writeContract, isLoading: isLoadingContract } = useNotifier({
		onSuccess: () => {
			setOpen(false)
		}
	})


    const hanbleSubmit = async () => {
        const amount = bid
		const { message, v, r, s }: any = await getSplitTransaction({
			token: payer,
			spender: contracts!.NFTAuction.address,
			amount,
			contract: payer === 'SAVVA' ? 'SavvaToken' : 'Staking'
		})

		writeContract({
			address: contracts.NFTAuction.address,
			abi: contracts.NFTAuction.abi,
			functionName: 'placeBidWithPermit',
			args: [activePost?.savva_cid, message.value, payer === 'SAVVA' ? contracts.SavvaToken.address : contracts.Staking.address, message.deadline, v, r, s]
		})
	}

    useEffect(() => {
        if(!open) return
        getConfig()

    }, [open])

    
    useEffect(() => {
        if(!nft) return
        const minBid = nft?.highest_bid > 0 ? nft?.highest_bid + (nft?.highest_bid * nft_auction_min_increment) / 100 : nft?.min_bid

        setBid(minBid / DEFAULT_PREC)
    }, [nft])

    const upperThenBalance = bid  > (payer === 'SAVVA_VOTES' ? stakedBalance : savvaBalance)

    if(!nft) return

    const minBid = nft?.highest_bid > 0 ? nft?.highest_bid + (nft?.highest_bid * nft_auction_min_increment) / 100 : nft?.min_bid
    const maxBid = nft?.highest_bid > 0 ? nft?.highest_bid + (nft?.highest_bid * nft_auction_max_increment) / 100 : nft?.min_bid + (nft?.min_bid * nft_auction_max_increment) / 100

    const low_or_hi = !bid || bid * DEFAULT_PREC < minBid || bid * DEFAULT_PREC > maxBid

    return (
		<Dialog onOpenChange={setOpen} open={open}>
			<DialogTrigger asChild>
				<Button className='w-full mt-4' size={'sm'} variant={'orange'}>
                    {langPack('nft.set_bid')}
				</Button>
			</DialogTrigger>
			<DialogContent className='sm:max-w-[825px]'>
				<CardTitle>{langPack('nft.auction')}</CardTitle>
                {nft?.highest_bid > 0 && <div className='flex flex-row items-center justify-between border-2 bg-background rounded-lg px-8 py-4 relative'>
                    <div className='absolute -top-[25px] left-1/2 transform -translate-x-1/2 border-2 p-2 px-8 bg-background rounded-lg '>{langPack('nft.last_bid')}</div>
                    <UserCard user={nft.bidder} className='mt-2'/>
                    <AmountBlock amount={nft.highest_bid} perUsd size='md' usdClassName='absolute -bottom-[10px]' />
                </div>    
                }
                <div className='flex flex-row justify-between w-full'>
                    <div className='flex flex-col w-[48%] items-center justify-center border-2 p-4 rounded-lg cursor-pointer hover:opacity-75' onClick={() => {setBid(minBid / DEFAULT_PREC)}}>
                        <Label>{langPack('nft.min_increment')}</Label>
                        <AmountBlock amount={minBid} size='md' perUsd usdClassName='absolute -bottom-[10px]'/>
                    </div>
                    <div className='flex flex-col w-[48%] items-center justify-center border-2 p-4 rounded-lg cursor-pointer hover:opacity-75'  onClick={() => {setBid(maxBid / DEFAULT_PREC)}}>
                        <Label>{langPack('nft.max_increment')}</Label>
                        <AmountBlock amount={maxBid} size='md' perUsd usdClassName='absolute -bottom-[10px]'/>
                    </div>
                </div>
                <div className='flex flex-col w-full text-center'>
                    {langPack('nft.select_balance')}
                </div>
                <div className='flex flex-row justify-between w-full'>
                    <SelectBlock
                        balance={savvaBalance}
                        label={langPack('default.balance')}
                        active={payer === 'SAVVA'}
                        onClick={() => setPayer('SAVVA')}
                    />
                    <SelectBlock
                        balance={stakedBalance}
                        label={langPack('profile.stake_balance')}
                        active={payer === 'SAVVA_VOTES'}
                        onClick={() => setPayer('SAVVA_VOTES')}
                    />
                </div>
                <div className='flex flex-col w-full'>
					<div className='text-[14px] text-[grey]'>{langPack('nft.size_of_bid')}</div>
					<AmountInput value={bid} onChange={setBid} token='SAVVA' />
				</div>
                <Button
                    className='w-full mt-5'
                    variant={'orange'}
                    size='lg'
                    disabled={upperThenBalance || isLoadingContract || low_or_hi}
                    loading={isLoadingContract}
                    onClick={hanbleSubmit}
                >
                    {langPack('nft.set_bid')}
                </Button>
			</DialogContent>
		</Dialog>
	)
}

export default BidModal
