import { ReactNode, useCallback, useState } from 'react'
import Axios, { AxiosResponse } from 'axios'
import { ConfigStore } from '../model/store'

export enum RequestTypes {
	get = 'get',
	post = 'post',
	put = 'put',
	delete = 'delete',
	patch = 'patch'
}

export enum ApiUrlEnum {
	logout = 'logout',
	auth = 'auth',
	contractInfo = 'info',
	contentList = 'content-list',
	auth_token = 'auth-token',
	react = 'react',
	getUser = 'get-user',
	getFollowers = 'get-followers',
	getFollowing = 'get-following',
	chatList = 'chat-list',
	getChat = 'chat-get',
	chatNew = 'chat-new',
	chatEdit = 'chat-edit',
	chatDelete = 'chat-delete',
	chatBanUser = 'chat-ban-user',
	chatBanMessage = 'chat-ban-post',
	getChatbannedUsers = 'chat-get-banned-users',
	chatUnBanUser = 'chat-unban-user',
	chatUnBanMessage = 'chat-unban-post',
	getFund = 'get-fund',
	isAdmin = 'is-admin',
	uploadFolderConfig = 'upload-temp-assets',
	ipfsAssets = 'ipfs-assets',
	getIpfsDir = 'ipfs-dir',
	uploadUserFiles = 'upload-user-files',
	getUserFiles = 'ls-user-dir',
	ipfsUserDir = 'ipfs-user-dir',
	deleteFile = 'delete-user-file',
	copyFolderCid = 'ipfs-to-user-folder',
	contentChildrensList = 'content-childs',
	reactions = 'get-reactions',
	commentLatest = 'latest-comments',
	commentLatestBy = 'latest-comments-by',
	getSponsees = 'get-sponsees',
	getSponsors = 'get-sponsors',
	getHistory = 'get-hist',
	getNfts = 'get-nfts',
	redeemPromo = 'redeem-promo',
	getList = 'get-list',
	getTokenPrices = 'get-token-prices',
	getActiveProposals = 'get-active-proposals',
	getUserFile = 'user-file',
	getUserCats = 'get-user-categories',
	getUserTags = 'get-user-tags',
	fix = 'fix',
	getProposals = 'get-proposals',
	listFundraisers = 'list-fundraisers'
}

export const requests = {
	auth: {
		url: ApiUrlEnum.auth,
		method: RequestTypes.get,
		protectedurl: false
	},
	fix: {
		url: ApiUrlEnum.fix,
		method: RequestTypes.get,
		protectedurl: false
	},
	commentLatestBy: {
		url: ApiUrlEnum.commentLatestBy,
		method: RequestTypes.get,
		protectedurl: false
	},
	getUserCats: {
		url: ApiUrlEnum.getUserCats,
		method: RequestTypes.get,
		protectedurl: false
	},
	getUserTags: {
		url: ApiUrlEnum.getUserTags,
		method: RequestTypes.get,
		protectedurl: false
	},
	logout: {
		url: ApiUrlEnum.logout,
		method: RequestTypes.get,
		protectedurl: true
	},
	getCommentLatest: {
		url: ApiUrlEnum.commentLatest,
		method: RequestTypes.get,
		protectedurl: false
	},
	getTokenPrices: {
		url: ApiUrlEnum.getTokenPrices,
		method: RequestTypes.get,
		protectedurl: false
	},
	getContractInfo: {
		url: ApiUrlEnum.contractInfo,
		method: RequestTypes.get,
		protectedurl: false
	},
	getFund: {
		url: ApiUrlEnum.getFund,
		method: RequestTypes.get,
		protectedurl: false
	},
	getUser: {
		url: ApiUrlEnum.getUser,
		method: RequestTypes.get,
		protectedurl: false
	},
	getContentList: {
		url: ApiUrlEnum.contentList,
		method: RequestTypes.get,
		protectedurl: false
	},
	getContentChildrensList: {
		url: ApiUrlEnum.contentChildrensList,
		method: RequestTypes.get,
		protectedurl: false
	},
	getFollowers: {
		url: ApiUrlEnum.getFollowers,
		method: RequestTypes.get,
		protectedurl: false
	},
	getFollowing: {
		url: ApiUrlEnum.getFollowing,
		method: RequestTypes.get,
		protectedurl: false
	},
	authToken: {
		url: ApiUrlEnum.auth_token,
		method: RequestTypes.get
	},
	sendReaction: {
		url: ApiUrlEnum.react,
		method: RequestTypes.get,
		protectedurl: true
	},
	getChatList: {
		url: ApiUrlEnum.chatList,
		method: RequestTypes.get,
		protectedurl: true
	},
	getChat: {
		url: ApiUrlEnum.getChat,
		method: RequestTypes.get,
		protectedurl: true
	},
	getChatbannedUsers: {
		url: ApiUrlEnum.getChatbannedUsers,
		method: RequestTypes.get,
		protectedurl: true
	},
	chatNew: {
		url: ApiUrlEnum.chatNew,
		method: RequestTypes.get,
		protectedurl: true
	},
	chatEdit: {
		url: ApiUrlEnum.chatEdit,
		method: RequestTypes.get,
		protectedurl: true
	},
	chatDelete: {
		url: ApiUrlEnum.chatDelete,
		method: RequestTypes.get,
		protectedurl: true
	},
	chatBanMessage: {
		url: ApiUrlEnum.chatBanMessage,
		method: RequestTypes.get,
		protectedurl: true
	},
	chatBanUser: {
		url: ApiUrlEnum.chatBanUser,
		method: RequestTypes.get,
		protectedurl: true
	},
	chatUnBanMessage: {
		url: ApiUrlEnum.chatUnBanMessage,
		method: RequestTypes.get,
		protectedurl: true
	},
	chatUnBanUser: {
		url: ApiUrlEnum.chatUnBanUser,
		method: RequestTypes.get,
		protectedurl: true
	},
	isAdmin: {
		url: ApiUrlEnum.isAdmin,
		method: RequestTypes.get,
		protectedurl: true
	},
	uploadFolderConfig: {
		url: ApiUrlEnum.uploadFolderConfig,
		method: RequestTypes.post,
		protectedurl: true
	},
	ipfsAssets: {
		url: ApiUrlEnum.ipfsAssets,
		method: RequestTypes.get,
		protectedurl: true
	},
	getIpfsDir: {
		url: ApiUrlEnum.getIpfsDir,
		method: RequestTypes.get,
		protectedurl: true
	},
	uploadUserFiles: {
		url: ApiUrlEnum.uploadUserFiles,
		method: RequestTypes.post,
		protectedurl: true
	},
	getUserFiles: {
		url: ApiUrlEnum.getUserFiles,
		method: RequestTypes.get,
		protectedurl: true
	},
	ipfsUserDir: {
		url: ApiUrlEnum.ipfsUserDir,
		method: RequestTypes.get,
		protectedurl: true
	},
	deleteFile: {
		url: ApiUrlEnum.deleteFile,
		method: RequestTypes.get,
		protectedurl: true
	},
	copyFolderCid: {
		url: ApiUrlEnum.copyFolderCid,
		method: RequestTypes.get,
		protectedurl: true
	},
	getReactions: {
		url: ApiUrlEnum.reactions,
		method: RequestTypes.get,
		protectedurl: true
	},
	getSponsees: {
		url: ApiUrlEnum.getSponsees,
		method: RequestTypes.get,
		protectedurl: true
	},
	getSponsors: {
		url: ApiUrlEnum.getSponsors,
		method: RequestTypes.get,
		protectedurl: true
	},
	getHistory: {
		url: ApiUrlEnum.getHistory,
		method: RequestTypes.get,
		protectedurl: true
	},
	getNfts: {
		url: ApiUrlEnum.getNfts,
		method: RequestTypes.get,
		protectedurl: true
	},
	redeemPromo: {
		url: ApiUrlEnum.redeemPromo,
		method: RequestTypes.get,
		protectedurl: false
	},
	getList: {
		url: ApiUrlEnum.getList,
		method: RequestTypes.get,
		protectedurl: false
	},
	getActiveProposals: {
		url: ApiUrlEnum.getActiveProposals,
		method: RequestTypes.get,
		protectedurl: false
	},
	getUserFile: {
		url: ApiUrlEnum.getUserFile,
		method: RequestTypes.get,
		protectedurl: true
	},
	getProposals: {
		url: ApiUrlEnum.getProposals,
		method: RequestTypes.get,
		protectedurl: false
	},
	listFundraisers: {
		url: ApiUrlEnum.listFundraisers,
		method: RequestTypes.get,
		protectedurl: false
	}
}

const timeout = 30000

interface ErrorRequest {
	isError: boolean
	errorMessage: string
}

export interface RequestOptionsTypes {
	url: string
	method: RequestTypes
	protectedurl?: boolean
}

interface IMessage {
	title: string
	description?: string
	icon?: ReactNode
}

interface RequestDataTypes {
	params?: object
	data?: object
	config?: object
	successMessage?: IMessage
	errorMessage?: IMessage
	addToUrl?: string
	onSuccess?: (res) => void
	onError?: (err) => void
	baseUrl?: string
	isMultipart?: boolean
	replace?: boolean
}

export default function useRequest<ResponseType>(requestKey: keyof typeof requests) {
	const { baseUrl: baseAppUrl } = ConfigStore()
	const [isLoading, setIsLoading] = useState<boolean>(false)
	const [error, setError] = useState<ErrorRequest>({ isError: false, errorMessage: '' })
	const [isSuccess, setIsSuccess] = useState<boolean>(false)
	const [result, setResult] = useState<ResponseType | null>(null)

	const { url, method, protectedurl }: RequestOptionsTypes = requests[requestKey]

	const { isError } = error

	const dropState = () => {
		setIsLoading(false)
		setIsSuccess(false)
		setError({ isError: false, errorMessage: '' })
		setResult(null)
	}

	const fetchRequest = async ({ data, params, onSuccess, onError, baseUrl, isMultipart, replace }: RequestDataTypes) => {

		const signature = ''

		const base = baseUrl || baseAppUrl

		const headers = {
			'Content-type': isMultipart ? 'multipart/form-data' : 'application/json',
			...(protectedurl && { Authorization: signature }),
			Accept: isMultipart ? 'multipart/form-data' : 'application/json'
		}
	
		const axiosInstance = Axios.create({
			timeout,
			headers,
			withCredentials: true 
		})
	
		axiosInstance.interceptors.request.use(
			config => config,
			err => Promise.reject(err)
		)

		axiosInstance.interceptors.response.use(
			response => response,
			err => Promise.reject(err)
		)

		try {
			const result: AxiosResponse = await axiosInstance({
				baseURL: base,
				method,
				url: replace ? '' : url,
				params,
				data
			})
			setResult(result?.data)
			setIsSuccess(true)
			onSuccess && onSuccess(result?.data as ResponseType)
			return result.data
		} catch (responseError: any) {
			
			if (responseError?.response?.status === 401) {
				// UserStore.setState({signatureAddress: null})
			}
		
			onError && onError(responseError.response)
			setError({
				isError: true,
				errorMessage: responseError.response || 'Empty error data'
			})
		} finally {
			setIsLoading(false)
		}
	}

	const fetch = useCallback(
		async ({
			data = {},
			params = {},
			successMessage,
			errorMessage,
			onError,
			onSuccess,
			baseUrl,
			isMultipart,
			replace
		}: RequestDataTypes) => {
			setError({ isError: false, errorMessage: '' })
			setIsLoading(true)
			await fetchRequest({
				data,
				params,
				successMessage,
				errorMessage,
				onError,
				onSuccess,
				baseUrl,
				replace,
				isMultipart
			})
			// eslint-disable-next-line react-hooks/exhaustive-deps
		},
		[]
	)

	return {
		isLoading,
		isError,
		isSuccess,
		fetch,
		result,
		dropState,
		error,
		setError,
		fetchRequest
	}
}
