import { useEffect, useState } from 'react'
import { Nft } from '../model/types'
import { LangStore } from '@/shared/model/store'
import { parceEthAmount } from '@/shared/lib'
import { CountdownTimer } from '@/shared/ui/countdown-timer'


const NftTooltip = ({nft}: {nft: Nft}) => {
    const [finish, setFinish] = useState<boolean>(Number(nft.auction_end_time) * 1000 < Date.now() || false)
	const [blink, setBlink] = useState<boolean>(false)
	const { langPack } = LangStore()

	useEffect(() => {
		blink && setTimeout(() => setBlink(false), 1500)
	}, [blink])
  return (
    <div className='flex flex-col items-center'>
        <div>{langPack('nft.auction')}</div>
        <div className='mt-2'>{finish ?  langPack('nft.last_bid') : nft.highest_bid > 0 ? langPack('nft.last_bid') : langPack('nft.be_first')}</div>
        <div className='font-bold text-[16px] mt-2'>{parceEthAmount(nft.highest_bid || nft.min_bid)} SAVVA</div>
        {!finish &&
        <>

            <div className='text-[12px] mt-2'>{langPack('nft.auction_end_time')}</div>
            <div className='z-40 mt-2 w-full'>
                <CountdownTimer
                    targetDate={Number(nft.auction_end_time) * 1000}
                    onFinish={state => setFinish(state)}
                />
        </div>
        </>
       }
    </div>
  )
}

export default NftTooltip