import { ConfigStore } from '@/shared/model/store'
import yaml from 'yaml'

export const getDefaultConnectYaml = async (url: string) => {
	try {
		const response = await fetch(url)

		const text = await response.text()
		const defaultConnect = yaml.parse(text)

		if (!defaultConnect.domain || !defaultConnect.backendLink) {
			throw new Error('Error: Missing domain or backend link')
		}

		return defaultConnect
	} catch (error) {
		console.error('Error: Missing domain or backend link:', error)
		throw error
	}
}

export const checkConfig = async (url: string) => {
	try {
		const config = ConfigStore.getState()
		const response = await fetch(url)

		const text = await response.text()
		const version = yaml.parse(text)

		if (!version.version) {
			throw new Error('Error: Missing domain or backend link')
		}

		if(config.version.version !== version.version){
			ConfigStore.setState({
				version: {
					version: version.version,
					reload: true
				}
			})
		}

		return version
	} catch (error) {
		console.error('Error: Missing domain or backend link:', error)
		throw error
	}
}